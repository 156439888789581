import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, LayersControl, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Box, Flex } from "@chakra-ui/react";
import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { colorMap2dGuoForMap } from "../../support/colors";
import { FaCuttlefish, FaCircle } from "react-icons/fa";

// Define the icon map
const iconMap: { [key: string]: JSX.Element } = {
  buoy: <FaCircle />,
  city: <FaCuttlefish />,
};

// Convert React icons to Leaflet DivIcons
const createDivIcon = (icon: JSX.Element, color: string) => {
  const iconHtml = ReactDOMServer.renderToString(icon);
  const divIcon = L.divIcon({
    className: "",
    html: `<div style="font-size: 24px; color: ${color};">${iconHtml}</div>`,
    iconSize: [24, 24],
  });
  return divIcon;
};

// Define the GeoJSONLayer component
const GeoJSONLayer: React.FC<{ data: any }> = ({ data }) => {
  const map = useMap();

  const getColors = (feature: any) => {
    const colorForFeature = colorMap2dGuoForMap.find(
      (mapping) => mapping.location_id === feature.properties.Name
    )?.color;

    return colorForFeature
      ? { color: `${colorForFeature}`, weight: 2 }
      : { color: "grey", weight: 2 };
  };

  useEffect(() => {
    if (data) {
      const geoJsonLayer = L.geoJSON(data, {
        pointToLayer: (feature, latlng) => {
          const iconKey = feature.properties["*Kind"];
          const icon = iconMap[iconKey] || iconMap["none"];
          const { color } = getColors(feature);
          const marker = new L.Marker(latlng, {
            icon: createDivIcon(icon, color),
          });

          const popupContent = `<strong>Name:</strong> ${feature.properties.Name}`;

          marker.bindTooltip(popupContent).openTooltip();

          return marker;
        },
        onEachFeature: (feature, layer) => {
          const name = Object.entries(feature.properties).find(
            (entry) => entry[0] === "Name"
          )?.[1];

          const kind = Object.entries(feature.properties).find(
            (entry) => entry[0] === "*Kind"
          )?.[1];

          const popupContent = `
          <strong>Name:</strong> ${name}
          <br/>
          <strong>Kind:</strong> ${kind}`;

          layer.bindTooltip(popupContent).openTooltip();
        },
        style: (feature) => {
          return getColors(feature);
        },
      });
      map.fitBounds(geoJsonLayer.getBounds());
      geoJsonLayer.addTo(map);
    }
  }, [data, map]);

  return null;
};

export type MapProps = {
  height: string;
  width: string;
};

// Component to invalidate map size
const InvalidateSize = () => {
  const map = useMap();
  useEffect(() => {
    map.invalidateSize();
  }, [map]);
  return null;
};

// Define the main Map component
export const Map = ({ height, width }: MapProps) => {
  const [geojsonData, setGeojsonData] = useState(null);

  useEffect(() => {
    fetch("/Oceanus.geojson")
      .then((response) => response.json())
      .then((data) => setGeojsonData(data))
      .catch((error) => console.error("Error loading GeoJSON:", error));
  }, []);

  return (
    <Flex direction="column" align="center" justify="center" height={height}>
      <Box
        width={width}
        height={height}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
      >
        <MapContainer
          center={[51.505, -0.09]}
          zoom={13}
          style={{ height: "100%", width: "100%" }}
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="OpenStreetMap">
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Grey Background">
              <TileLayer
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.carto.com/attribution">CARTO</a>'
              />
            </LayersControl.BaseLayer>
            {geojsonData && <GeoJSONLayer data={geojsonData} />}
            <InvalidateSize />
          </LayersControl>
        </MapContainer>
      </Box>
    </Flex>
  );
};
