import React, { useState, useEffect } from "react";
import { Group } from "@visx/group";
import { scaleBand, scaleOrdinal } from "@visx/scale";
import { AxisTop, AxisLeft } from "@visx/axis";
import { schemeTableau10 } from "d3-scale-chromatic";
import { ScaleOrdinal } from "d3-scale";

interface HeatMapProps {
  width: number;
  height: number;
  data: SelectedData | null;
  viewMode: "default" | "highlight";
  fishInfo: { name: string; fishing: string }[];
}

interface ScoreData {
  avg_plausibility: number;
  total_qty_tons: number;
}

interface DataStructure {
  delivery_reports: object[];
  scores: { [fish: string]: ScoreData };
}

interface SelectedData {
  type: "week" | "vessel";
  selection: string;
  data: { [key: string]: DataStructure };
}

const vesselScale = scaleBand<string>({
  padding: 0.1,
});

const fishScale = scaleBand<string>({
  padding: 0.1,
});

const weekScale = scaleBand<string>({
  padding: 0.1,
});

interface TooltipData {
  vessel: string;
  fish: string;
  value: number;
  x: number;
  y: number;
}

export const PlausibilityMap: React.FC<HeatMapProps> = ({
  width,
  height,
  data,
  viewMode,
  fishInfo,
}) => {
  const [highlightedRect, setHighlightedRect] = useState<{
    vessel: string;
    fish: string;
  } | null>(null);
  const [tooltip, setTooltip] = useState<TooltipData | null>(null);

  const margin = {
    top: data?.type === "vessel" ? 60 : 125,
    left: 100,
    right: 10,
    bottom: 10,
  };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  useEffect(() => {
    vesselScale.range([0, innerWidth]);
    fishScale.range([0, innerHeight]);
    weekScale.range([0, innerWidth]);
  }, [innerWidth, innerHeight]);

  const handleMouseOut = () => {
    setHighlightedRect(null);
    setTooltip(null);
  };

  const handleMouseOverRect = (
    event: React.MouseEvent<SVGRectElement, MouseEvent>,
    vessel: string,
    fish: string,
    value: number
  ) => {
    const svgRect = (event.target as SVGRectElement)
      .closest("svg")!
      .getBoundingClientRect();
    const { clientX, clientY } = event;
    setHighlightedRect({ vessel, fish });
    setTooltip({
      vessel,
      fish,
      value,
      x: clientX - svgRect.left,
      y: clientY - svgRect.top,
    });
  };

  if (!data) {
    return <div>No avaiable data</div>;
  }

  let xDomain: string[],
    yDomain: string[],
    xScale: typeof vesselScale | typeof weekScale;

  if (data.type === "week") {
    xDomain = Object.keys(data.data).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    yDomain = fishInfo.map((fish) => fish.name);
    xScale = weekScale;
  } else {
    xDomain = Object.keys(data.data);
    yDomain = fishInfo.map((fish) => fish.name);
    xScale = vesselScale;
  }

  xScale.domain(xDomain);
  fishScale.domain(yDomain);

  let colorScale: ScaleOrdinal<string, string>;
  if (viewMode === "highlight") {
    colorScale = scaleOrdinal<string, string>({
      domain: fishInfo.map((fish) => fish.name),
      range: fishInfo.map((fish) => {
        switch (fish.fishing) {
          case "allowed":
            return "#59a14f";
          case "forbidden":
            return "#e15759";
          case "ambiguous":
            return "#edc949";
          default:
            return "grey";
        }
      }),
    });
  } else {
    colorScale = scaleOrdinal<string, string>({
      domain: fishInfo.map((fish) => fish.name),
      range: [...schemeTableau10] as string[], // Cast the read-only array to a mutable array type
    });
  }

  return (
    <>
      <svg width={width} height={height} id="heatmap-container">
        <Group left={margin.left} top={margin.top}>
          {xDomain.map((xValue) => (
            <Group key={`xValue-${xValue}`}>
              {yDomain.map((yValue) => {
                let value = 0;
                const dataStructure = data.data[xValue];

                const scoreData = dataStructure.scores[yValue];
                if (scoreData) {
                  value = scoreData.avg_plausibility ?? 0;
                }

                const isHighlightedRect =
                  highlightedRect &&
                  highlightedRect.vessel === xValue &&
                  highlightedRect.fish === yValue;

                const opacity = Math.min(Math.max((value + 0.01) * 10, 0.1), 1);

                return (
                  <rect
                    key={`rect-${xValue}-${yValue}`}
                    x={xScale(xValue)}
                    y={fishScale(yValue)}
                    width={xScale.bandwidth()}
                    height={fishScale.bandwidth()}
                    fill={colorScale(yValue)}
                    onMouseOver={(event) =>
                      handleMouseOverRect(event, xValue, yValue, value)
                    }
                    onMouseOut={handleMouseOut}
                    opacity={opacity}
                    stroke={isHighlightedRect ? "black" : "none"}
                    strokeWidth={isHighlightedRect ? 2 : 0}
                  />
                );
              })}
            </Group>
          ))}
          <AxisTop
            top={0}
            scale={xScale}
            tickFormat={(d) => d}
            tickLabelProps={(value) => ({
              angle: -45,
              textAnchor: "start",
              fontSize: 10,
              dx: 3,
            })}
            numTicks={xDomain.length}
          />
          <AxisLeft
            scale={fishScale}
            tickLabelProps={(value) => ({
              angle: -45,
              fontSize: 10,
              textAnchor: "end",
              dy: 3,
            })}
            numTicks={yDomain.length}
          />
        </Group>
      </svg>
      {tooltip && (
        <div
          style={{
            position: "absolute",
            top: tooltip.y + margin.top,
            left: tooltip.x + margin.left,
            backgroundColor: "white",
            border: "1px solid black",
            padding: "5px",
            pointerEvents: "none",
            zIndex: 99999,
          }}
        >
          <div>
            <strong>{data.type === "week" ? "Vessel" : "Week"}:</strong>{" "}
            {tooltip.vessel}
          </div>
          <div>
            <strong>Fish:</strong> {tooltip.fish}
          </div>
          <div>
            <strong>Plausibility:</strong> {tooltip.value.toFixed(2)}
          </div>
        </div>
      )}
    </>
  );
};
