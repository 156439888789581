import { useEffect, useRef } from "react";
import { colorToLocation } from "../../support/colors";

export type CanvasProps = {
  height: number;
  width: number;
  colors: colorToLocation[][];
};

export const Canvas = ({ height, width, colors }: CanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const lineThickness = 1;

  const draw = (ctx: any) => {
    // Clear the canvas
    ctx.clearRect(0, 0, width, height);

    // Draw the visible colors
    colors?.forEach((row, outerIndex) => {
      row.forEach((pixel, index) => {
        ctx.fillStyle = pixel.color;
        ctx.fillRect(index, outerIndex, 1, lineThickness);
      });
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (context) {
      draw(context);
    }
  }, []);

  return <canvas ref={canvasRef} height={height} width={width} />;
};
