import * as React from "react";
import {
  Box,
  ChakraProvider,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  IconButton,
  Alert,
  AlertIcon,
  CloseButton,
  Spacer,
  Icon,
  Image,
  Link,
  Tag,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { SlSizeFullscreen } from "react-icons/sl";
import "./App.css";

import { useState, useEffect } from "react";
import { PieGridView } from "./components/PieGrid/PieGridView";
import { PixelVizView } from "./components/PixelViz/PixelVizView";
import { SequenceView } from "./components/SequenceMap/SequenceView";
import { PlausibilityMapView } from "./components/PlausibilityMap/PlausibilityMapView";
import { MapView } from "./components/Map/MapView";

import { locationId } from "./components/PixelViz/mock_new";
import { ClustersView } from "./components/Clusters/ClustersView";

type PanelType = "left" | "right";

export type Vessel = {
  vesselCompany: string;
  vesselID: string;
  vesselLabel: string;
  vesselName: string;
};

export type PixelData = {
  id: string;
  location_ids: locationId[][];
  params: {
    layout: string;
  };
};

export const BACKEND_URL = "/api";

function App() {
  const [dataElement, setDataElement] = useState(null);
  const [vesselData, setVesselData] = useState<Vessel[]>([]);
  const [fullscreenPanel, setFullscreenPanel] = useState<PanelType | null>(
    null
  );
  const [alertIsVisible, setAlertIsVisible] = useState(false);

  const toggleFullscreen = (panel: PanelType) => {
    setFullscreenPanel(fullscreenPanel === panel ? null : panel);
  };

  useEffect(() => {
    fetch(`${BACKEND_URL}/node_count`)
      .then((response) => {
        return response.text();
      })
      .then((data) => JSON.parse(data))
      .then((data) => {
        setDataElement(data.node_count);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const fetchVesselData = () => {
    fetch(`${BACKEND_URL}/vessels`)
      .then((response) => {
        return response.text();
      })
      .then((data) => JSON.parse(data))
      .then((data) => {
        setVesselData(data.vessels);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        console.log("data fetching is done");
      });
  };
  const [selectedObject, setSelectedObject] = useState<any>(null);
  const handleSelection = (selectedData: any) => {
    console.log(selectedData);
    setSelectedObject(selectedData);
  };

  //////////////////////////////////////
  // all render functions go here

  const renderPanel = (panel: PanelType) => {
    return (
      <Box
        borderRadius="lg"
        className={`panel ${
          fullscreenPanel !== null
            ? fullscreenPanel === panel
              ? "fullscreen-panel"
              : "not-fullscreen-panel"
            : ""
        }`}
      >
        {(fullscreenPanel === null || fullscreenPanel === panel) && (
          <Tabs
            isLazy
            colorScheme="blue"
            variant="line"
            isFitted
            defaultIndex={panel === "left" ? 0 : 1}
          >
            <TabList>
              <Tab>Pie Grid</Tab>
              <Tab>PixelViz</Tab>
              <Tab>Sequences</Tab>
              <Tab>Plausibility</Tab>
              <Tab>Map</Tab>
              <Tab>Clusters</Tab>
              <IconButton
                aria-label="Toggle Fullscreen"
                icon={
                  fullscreenPanel === panel ? (
                    <CloseIcon />
                  ) : (
                    <Icon as={SlSizeFullscreen} />
                  )
                }
                onClick={() => toggleFullscreen(panel)}
              />
            </TabList>

            <TabPanels>
              <TabPanel>
                <PieGridView
                  onSelection={handleSelection}
                  vesselData={vesselData}
                />
              </TabPanel>
              <TabPanel>
                {vesselData?.length >= 196 ? (
                  <PixelVizView vesselData={vesselData} />
                ) : (
                  <>Loading data...</>
                )}
              </TabPanel>
              <TabPanel>
                <SequenceView vesselData={vesselData} />
              </TabPanel>
              <TabPanel>
                <PlausibilityMapView selectedObject={selectedObject} />
              </TabPanel>
              <TabPanel>
                <MapView />
              </TabPanel>
              <TabPanel>
                <ClustersView></ClustersView>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Box>
    );
  };

  useEffect(() => {
    fetchVesselData();
  }, []);

  useEffect(() => {
    setAlertIsVisible(true);
  }, [dataElement]);

  return (
    <ChakraProvider>
      {alertIsVisible && (
        <>
          {dataElement ? (
            <Alert status="success">
              <Flex direction={"row"} width={"100%"} align={"center"}>
                <>
                  <AlertIcon />
                  Connection to backend established.
                </>
                <Spacer />
                <CloseButton onClick={() => setAlertIsVisible(false)} />
              </Flex>
            </Alert>
          ) : (
            <Alert status="error">
              <Flex direction={"row"} width={"100%"} align={"center"}>
                <>
                  <AlertIcon />
                  Can't connect to backend, trying again...
                </>
                <Spacer />
                <CloseButton onClick={() => setAlertIsVisible(false)} />
              </Flex>
            </Alert>
          )}
        </>
      )}
      <Box
        mx="1rem"
        my="0.5rem"
        p="1rem"
        borderWidth="1px"
        borderRadius="lg"
        backgroundColor="#003459"
        color="#ffffff"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Image
          src="/vizzard_logo.png"
          alt="Fishing Vizzard"
          width="50px"
          mr="1rem"
        />
        <Heading textAlign="center">
          VAST Challenge 2024 - MC2: Fishing Vizzard
        </Heading>
        <Image
          src="/vizzard_logo.png"
          alt="Fishing Vizzard"
          width="50px"
          ml="1rem"
        />
      </Box>

      <Flex gap={fullscreenPanel ? "" : "1rem"} ml="1rem" mr="1rem">
        {renderPanel("left")}
        {renderPanel("right")}
      </Flex>
      <Box
        as="footer"
        mx="1rem"
        mt="0.5rem"
        p="1rem"
        borderWidth="1px"
        borderRadius="lg"
        backgroundColor="#003459"
        color="#ffffff"
      >
        <Flex alignItems="center">
          <Link href="https://www.vis.uni-konstanz.de/" isExternal>
            <Tag backgroundColor="#ffffff" color="#003459">
              University of Konstanz - DBVIS
            </Tag>
          </Link>
          <Spacer />
          <Flex>
            <Tag backgroundColor="#ffffff" color="#003459" mr="1rem">
              Antonella Bidlingmaier
            </Tag>
            <Tag backgroundColor="#ffffff" color="#003459" mr="1rem">
              Fred Kunze
            </Tag>
            <Tag backgroundColor="#ffffff" color="#003459" mr="1rem">
              Julian Jandeleit
            </Tag>
            <Tag backgroundColor="#ffffff" color="#003459" mr="1rem">
              Lisa-Maria Reutlinger
            </Tag>
            <Tag backgroundColor="#ffffff" color="#003459">
              Tolga Tuncer
            </Tag>
          </Flex>
        </Flex>
      </Box>
    </ChakraProvider>
  );
}

export default App;
