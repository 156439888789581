import React, { useEffect, useState } from "react";
import {
  ChakraProvider,
  Box,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
  HStack,
  ButtonGroup,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import {
  FaBarcode,
  FaAdjust,
  FaSortAlphaDown,
  FaSitemap,
  FaChartPie,
  FaCodiepie,
} from "react-icons/fa";
import { PieGrid } from "./PieGrid";
import { FilterBox, cluster } from "../../elements/FilterBox/FilterBox";
import { BACKEND_URL, Vessel } from "../../App";
import "./PieGridView.css";

interface ScoreData {
  avg_plausibility: number;
  total_qty_tons: number;
}

interface WeekData {
  delivery_reports: object[];
  scores: { [fish: string]: ScoreData };
}

interface VesselData {
  [week: string]: WeekData;
}

interface CombinedData {
  [vessel: string]: VesselData;
}

interface PieGridViewProps {
  onSelection: (selectedData: any) => void;
  vesselData: Vessel[];
}

export type appliedFilters = {
  vesseltype: string[];
  company: string[];
  vesselIds: string[];
  cluster: string[];
  month: string | undefined;
} | null;

const fishInfo = [
  { name: "Cod/Gadus n.specificatae", fishing: "allowed" },
  { name: "Birdseye/Pisces frigus", fishing: "ambiguous" },
  { name: "Sockfish/Pisces foetida", fishing: "forbidden" },
  { name: "Wrasse/Labridae n.refert", fishing: "ambiguous" },
  { name: "Beauvoir/Habeas pisces", fishing: "ambiguous" },
  { name: "Harland/Piscis sapidum", fishing: "allowed" },
  { name: "Tuna/Thunnini n.vera", fishing: "ambiguous" },
  { name: "Offidiaa/Piscis osseus", fishing: "forbidden" },
  { name: "Salmon/Oncorhynchus rosea", fishing: "forbidden" },
  { name: "Sockfish/Pisces foetida", fishing: "forbidden" },
  { name: "Helenaa/Pisces satis", fishing: "forbidden" },
];

export const PieGridView: React.FC<PieGridViewProps> = ({
  onSelection,
  vesselData,
}) => {
  const [data, setData] = useState<CombinedData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [threshold, setThreshold] = useState<number>(0);
  const [width, setWidth] = useState<number>(500);
  const [height, setHeight] = useState<number>(500);
  const [viewMode, setViewMode] = useState<"default" | "highlight">("default");
  const [appliedFilters, setAppliedFilters] = useState<appliedFilters>(null);
  const [clusterList, setClusterList] = useState<cluster[]>([]);
  const [sortMode, setSortMode] = useState<"alphabetical" | "cluster">(
    "alphabetical"
  );
  const [opacityMode, setOpacityMode] = useState<"default" | "highlight">(
    "default"
  );

  const handleSetFilters = (filters: appliedFilters) => {
    setAppliedFilters(filters);
  };

  const handleSetClusterList = (clusters: cluster[]) => {
    setClusterList(clusters);
  };

  const getSortedandFilteredData = (dataFilter: string[]) => {
    const filterByCompany = (dataFilter: string[]) => {
      return dataFilter.filter((vesselId) => {
        const vesselWithData = vesselData.find(
          (vessel) => vessel.vesselID === vesselId
        );
        return vesselWithData?.vesselCompany
          ? appliedFilters?.company.includes(vesselWithData.vesselCompany)
          : false;
      });
    };

    const filterByType = (dataFilter: string[]) => {
      return dataFilter.filter((vesselId) => {
        const vesselWithData = vesselData.find(
          (vessel) => vessel.vesselID === vesselId
        );
        return vesselWithData?.vesselLabel
          ? appliedFilters?.vesseltype.includes(vesselWithData.vesselLabel)
          : false;
      });
    };

    const filterByIds = (dataFilter: string[]) => {
      return dataFilter.filter((vesselId) => {
        return appliedFilters?.vesselIds.includes(vesselId);
      });
    };

    const filterByClusters = (dataFilter: string[]) => {
      const selectedClusters = appliedFilters?.cluster || [];
      const clusterVessels = selectedClusters.flatMap((clusterId) => {
        const cluster = clusterList.find((cluster) => cluster.id === clusterId);
        return cluster ? cluster.vessels : [];
      });

      return dataFilter.filter((vesselId) => clusterVessels.includes(vesselId));
    };

    let returnData = dataFilter;

    if (
      appliedFilters?.company !== undefined &&
      appliedFilters?.company?.length >= 1
    ) {
      returnData = filterByCompany(returnData);
    }

    if (
      appliedFilters?.vesseltype !== undefined &&
      appliedFilters?.vesseltype?.length >= 1
    ) {
      returnData = filterByType(returnData);
    }

    if (
      appliedFilters?.vesselIds !== undefined &&
      appliedFilters?.vesselIds?.length >= 1
    ) {
      returnData = filterByIds(returnData);
    }

    if (
      appliedFilters?.cluster !== undefined &&
      appliedFilters?.cluster?.length >= 1
    ) {
      returnData = filterByClusters(returnData);
    }

    if (sortMode === "alphabetical") {
      returnData.sort();
    } else if (sortMode === "cluster") {
      const vesselToClusterMap: { [vesselId: string]: number } = {};
      clusterList.forEach((cluster) => {
        cluster.vessels.forEach((vesselId) => {
          vesselToClusterMap[vesselId] = parseInt(cluster.id);
        });
      });

      returnData.sort((a, b) => {
        const clusterA = vesselToClusterMap[a];
        const clusterB = vesselToClusterMap[b];

        if (clusterA === clusterB) {
          return a.localeCompare(b); // Alphabetical order within the same cluster
        }

        return (clusterA ?? Infinity) - (clusterB ?? Infinity); // Cluster order
      });
    }

    return returnData;
  };

  const preprocessData = (data: CombinedData): CombinedData => {
    // Get all unique weeks
    const allWeeks = new Set<string>();
    Object.values(data).forEach((vesselData) => {
      Object.keys(vesselData).forEach((week) => {
        allWeeks.add(week);
      });
    });

    // Ensure all vessels have all weeks
    Object.keys(data).forEach((vessel) => {
      allWeeks.forEach((week) => {
        if (!data[vessel][week]) {
          data[vessel][week] = { delivery_reports: [], scores: {} };
        }
      });
    });

    return data;
  };

  useEffect(() => {
    fetch(`${BACKEND_URL}/piegrid`)
      .then((response) => response.json())
      .then((data) => {
        const processedData = preprocessData(data.grid);
        setData(processedData);
        setLoading(false);
      })
      .catch((error) => {
        setError("Failed to fetch data");
        setLoading(false);
      });
  }, []);

  const handleSliderChange = (value: number) => {
    setThreshold(value);
  };

  const handleWidthChange = (value: number) => {
    setWidth(value);
  };

  const handleHeightChange = (value: number) => {
    setHeight(value);
  };

  const toggleViewMode = (mode: "default" | "highlight") => {
    setViewMode(mode);
  };

  const toggleSortMode = (mode: "alphabetical" | "cluster") => {
    setSortMode(mode);
  };

  const toggleOpacityMode = (mode: "default" | "highlight") => {
    setOpacityMode(mode);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const filteredVesselIDs = getSortedandFilteredData(
    vesselData.map((vessel) => vessel.vesselID)
  );

  const filteredData: CombinedData | null = data
    ? Object.fromEntries(filteredVesselIDs.map((key) => [key, data[key]]))
    : null;

  const isEmptyData = !filteredData || Object.keys(filteredData).length === 0;

  const noFiltersSelected =
    !appliedFilters?.company?.length &&
    !appliedFilters?.vesseltype?.length &&
    !appliedFilters?.vesselIds?.length &&
    !appliedFilters?.cluster?.length;

  return (
    <ChakraProvider>
      <FilterBox
        vessels={vesselData}
        handleSetFilters={handleSetFilters}
        setClusterList={handleSetClusterList}
      />

      <Box p="4" bg="gray.100" borderRadius="md" mb="4">
        <Flex justifyContent="space-between">
          <Flex flex={6}>
            <ButtonGroup size="sm" isAttached variant="outline" flex={2}>
              <IconButton
                icon={<FaBarcode />}
                isActive={viewMode === "default"}
                aria-label="Default view"
                onClick={() => toggleViewMode("default")}
              />
              <IconButton
                icon={<FaAdjust />}
                isActive={viewMode === "highlight"}
                aria-label="Highlight view"
                onClick={() => toggleViewMode("highlight")}
              />
            </ButtonGroup>

            <ButtonGroup size="sm" isAttached variant="outline" flex={2}>
              <IconButton
                icon={<FaChartPie />}
                isActive={opacityMode === "default"}
                aria-label="Default view"
                onClick={() => toggleOpacityMode("default")}
              />
              <IconButton
                icon={<FaCodiepie />}
                isActive={opacityMode === "highlight"}
                aria-label="Highlight view"
                onClick={() => toggleOpacityMode("highlight")}
              />
            </ButtonGroup>
            <ButtonGroup size="sm" isAttached variant="outline" flex={2}>
              <IconButton
                icon={<FaSortAlphaDown />}
                isActive={sortMode === "alphabetical"}
                aria-label="Sort alphabetically"
                onClick={() => toggleSortMode("alphabetical")}
              />
              <IconButton
                icon={<FaSitemap />}
                isActive={sortMode === "cluster"}
                aria-label="Sort by cluster"
                onClick={() => toggleSortMode("cluster")}
              />
            </ButtonGroup>

            <HStack spacing="12px" flex={8}>
              <Text fontWeight="bold"> Threshold:</Text>
              <Text>{threshold.toFixed(2)}</Text>
              <Slider
                defaultValue={0}
                min={0}
                max={0.1}
                step={0.01}
                width="100px"
                onChange={handleSliderChange}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </HStack>
          </Flex>

          <Flex flex={4} justifyContent="space-between">
            <HStack spacing="12px" flex={5}>
              <Text fontWeight="bold"> Width:</Text>
              <Text>{width}</Text>
              <Slider
                defaultValue={500}
                min={500}
                max={10000}
                step={50}
                width="75px"
                onChange={handleWidthChange}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </HStack>
            <HStack spacing="12px" flex={5}>
              <Text fontWeight="bold"> Height:</Text>
              <Text>{height}</Text>
              <Slider
                defaultValue={500}
                min={500}
                max={10000}
                step={50}
                width="75px"
                onChange={handleHeightChange}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </HStack>
          </Flex>
        </Flex>
      </Box>
      <Box p="2" style={{ overflowX: "auto", overflowY: "auto" }}>
        {noFiltersSelected ? (
          <Box textAlign="center" mt="4">
            <Text fontSize="xl" color="blue.500">
              Please select at least one filtering option first
            </Text>
          </Box>
        ) : !isEmptyData ? (
          <PieGrid
            width={width}
            height={height}
            data={filteredData}
            threshold={threshold}
            viewMode={viewMode}
            fishInfo={fishInfo}
            onSelection={onSelection}
            clusterList={clusterList}
            opacityMode={opacityMode}
          />
        ) : (
          <Box textAlign="center" mt="4">
            <Text fontSize="xl" color="red.500">
              No data for given selection
            </Text>
          </Box>
        )}
      </Box>
    </ChakraProvider>
  );
};
