import React, { useState, useEffect } from "react";
import { Sequences } from "./Sequences";
import {
  ChakraProvider,
  Box,
  Text,
  HStack,
  ButtonGroup,
  IconButton,
} from "@chakra-ui/react";
import { FaTag, FaStar, FaSortAlphaDown, FaSitemap } from "react-icons/fa";
import { Vessel } from "../../App";
import { FilterBox } from "../../elements/FilterBox/FilterBox";

interface SequenceViewProps {
  vesselData: Vessel[];
}

export type cluster = { id: string; vessels: string[] };

export type MapVessel = {
  dwellTime: number;
  locationID: string;
  locationName: string;
  startTime: string;
  vesselID: string;
};

export type appliedFilters = {
  vesseltype: string[];
  company: string[];
  vesselIds: string[];
  cluster: string[];
} | null;

export const SequenceView = ({ vesselData }: SequenceViewProps) => {
  const [appliedFilters, setAppliedFilters] = useState<appliedFilters>(null);
  const [clusterList, setClusterList] = useState<cluster[]>([]);
  const [viewMode, setViewMode] = useState<"tag" | "icon">("tag");
  const [sortMode, setSortMode] = useState<"alphabetical" | "cluster">(
    "alphabetical"
  );

  const [filteredVesselData, setFilteredVesselData] = useState<Vessel[]>([]);

  const handleSetFilters = (filters: appliedFilters) => {
    console.log("handleSetFilters");
    setAppliedFilters(filters);
  };

  const handleSetClusterList = (clusters: cluster[]) => {
    console.log("handleSetClusters");
    setClusterList(clusters);
  };

  const toggleViewMode = (mode: "tag" | "icon") => {
    setViewMode(mode);
  };

  const toggleSortMode = (mode: "alphabetical" | "cluster") => {
    setSortMode(mode);
  };

  const getSortedandFilteredData = (data: Vessel[]) => {
    const filterByCompany = (data: Vessel[]) => {
      return data.filter((vessel) => {
        return vessel?.vesselCompany
          ? appliedFilters?.company.includes(vessel.vesselCompany)
          : false;
      });
    };

    const filterByType = (data: Vessel[]) => {
      return data.filter((vessel) => {
        return vessel?.vesselLabel
          ? appliedFilters?.vesseltype.includes(vessel.vesselLabel)
          : false;
      });
    };

    const filterByIds = (data: Vessel[]) => {
      return data.filter((vessel) => {
        return appliedFilters?.vesselIds.includes(vessel.vesselID);
      });
    };

    const filterByClusters = (data: Vessel[]) => {
      const selectedClusters = appliedFilters?.cluster || [];
      const clusterVessels = selectedClusters.flatMap((clusterId) => {
        const cluster = clusterList.find((cluster) => cluster.id === clusterId);
        return cluster ? cluster.vessels : [];
      });

      return data.filter((vessel) => clusterVessels.includes(vessel.vesselID));
    };

    let returnData = data;

    if (
      appliedFilters?.company !== undefined &&
      appliedFilters?.company?.length >= 1
    ) {
      returnData = filterByCompany(returnData);
    }

    if (
      appliedFilters?.vesseltype !== undefined &&
      appliedFilters?.vesseltype?.length >= 1
    ) {
      returnData = filterByType(returnData);
    }

    if (
      appliedFilters?.vesselIds !== undefined &&
      appliedFilters?.vesselIds?.length >= 1
    ) {
      returnData = filterByIds(returnData);
    }

    if (
      appliedFilters?.cluster !== undefined &&
      appliedFilters?.cluster?.length >= 1
    ) {
      returnData = filterByClusters(returnData);
    }

    if (sortMode === "alphabetical") {
      returnData.sort((a, b) => a.vesselName.localeCompare(b.vesselName));
    } else if (sortMode === "cluster") {
      const vesselToClusterMap: { [vesselId: string]: number } = {};
      clusterList.forEach(cluster => {
        cluster.vessels.forEach(vesselId => {
          vesselToClusterMap[vesselId] = parseInt(cluster.id);
        });
      });

      returnData.sort((a, b) => {
        const clusterA = vesselToClusterMap[a.vesselID];
        const clusterB = vesselToClusterMap[b.vesselID];

        if (clusterA === clusterB) {
          return a.vesselName.localeCompare(b.vesselName); // Alphabetical order within the same cluster
        }

        return (clusterA ?? Infinity) - (clusterB ?? Infinity); // Cluster order
      });
    }

    return returnData;
  };

  useEffect(() => {
    setFilteredVesselData(getSortedandFilteredData(vesselData));
  }, [vesselData, appliedFilters, sortMode]);

  return (
    <ChakraProvider>
      <FilterBox
        vessels={vesselData}
        handleSetFilters={handleSetFilters}
        setClusterList={handleSetClusterList}
      />

      <Box p="4" bg="gray.100" borderRadius="md" mb="4">
        <HStack justifyContent="space-between">
          <ButtonGroup size="sm" isAttached variant="outline">
            <IconButton
              icon={<FaTag />}
              isActive={viewMode === "tag"}
              aria-label="Tag view"
              onClick={() => toggleViewMode("tag")}
            />
            <IconButton
              icon={<FaStar />}
              isActive={viewMode === "icon"}
              aria-label="Icon view"
              onClick={() => toggleViewMode("icon")}
            />
          </ButtonGroup>
          <ButtonGroup size="sm" isAttached variant="outline">
            <IconButton
              icon={<FaSortAlphaDown />}
              isActive={sortMode === "alphabetical"}
              aria-label="Sort alphabetically"
              onClick={() => toggleSortMode("alphabetical")}
            />
            <IconButton
              icon={<FaSitemap />}
              isActive={sortMode === "cluster"}
              aria-label="Sort by cluster"
              onClick={() => toggleSortMode("cluster")}
            />
          </ButtonGroup>
        </HStack>
      </Box>

      <Box p="2" style={{ overflowX: "auto", overflowY: "auto" }}>
        {filteredVesselData != null && filteredVesselData.length < 296 ? (
          <Sequences
            vessels={filteredVesselData}
            viewMode={viewMode}
            clusterList={clusterList}
          />
        ) : (
          <Box textAlign="center" mt="4">
            <Text fontSize="xl" color="blue.500">
              Please select at least one filtering option first
            </Text>
          </Box>
        )}
      </Box>
    </ChakraProvider>
  );
};
