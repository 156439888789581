import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
  Tooltip,
  Tag,
  useControllableState,
  HStack,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { Vessel } from "../../App";
import { appliedFilters, cluster } from "./PixelVizView";
// import TimeFilter from "./TimeFilter";

export type PixelFilterBoxProps = {
  vessels: Vessel[];
  handleSetFilters: (filters: appliedFilters) => void;
  clusterList: cluster[];
};

export const PixelFilterBox = ({
  vessels,
  handleSetFilters,
  clusterList,
}: PixelFilterBoxProps) => {
  const [sortAttribute, setSortAttribute] = useState<string>("similarity");
  const [chosenVesselIdForSorting, setChosenVesselIdForSorting] =
    useState<string>();
  // const [
  //   chosenVesselIdForSortingInternal,
  //   setChosenVesselIdForSortingInternal,
  // ] = useControllableState({
  //   value: chosenVesselIdForSorting,
  //   onChange: setChosenVesselIdForSorting,
  // });

  const [vesselType, setVesselType] = useState<string[]>([]);
  const [vesselTypeInternal, setVesselTypeInternal] = useControllableState({
    value: vesselType,
    onChange: setVesselType,
  });

  const [company, setCompany] = useState<string[]>([]);
  const [companyInternal, setCompanyInternal] = useControllableState({
    value: company,
    onChange: setCompany,
  });

  const [vesselIds, setVesselIds] = useState<string[]>([]);
  const [vesselIdsInternal, setVesselIdsInternal] = useControllableState({
    value: vesselIds,
    onChange: setVesselIds,
  });

  const [month, setMonth] = useState<string>();
  const [monthInternal, setMonthInternal] = useControllableState({
    value: month,
    onChange: setMonth,
  });

  const [cluster, setCluster] = useState<string[]>([]);
  const [clusterInternal, setClusterInternal] = useControllableState({
    value: cluster,
    onChange: setCluster,
  });

  let months: { id: string; label: string }[] = [
    { id: "2_february", label: "February" },
    { id: "3_march", label: "March" },
    { id: "4_april", label: "April" },
    { id: "5_may", label: "May" },
    { id: "6_june", label: "June" },
    { id: "7_july", label: "July" },
    { id: "8_august", label: "August" },
    { id: "9_september", label: "September" },
    { id: "10_october", label: "October" },
    { id: "11_november", label: "November" },
  ];

  let vesseltypes: { id: string; label: string }[] = [
    { id: "Entity.Vessel.FishingVessel", label: "Fishing Vessels" },
    { id: "Entity.Vessel.CargoVessel", label: "Cargo Vessels" },
    { id: "Entity.Vessel.Ferry.Passenger", label: "Passenger Ferries" },
    { id: "Entity.Vessel.Ferry.Cargo", label: "Cargo Ferries" },
    { id: "Entity.Vessel.Tour", label: "Tour Vessels" },
    { id: "Entity.Vessel.Research", label: "Research Vessels" },
    { id: "Entity.Vessel.Other", label: "Other Vessels" },
  ];

  let vesselsPerType: { id: string; amount: number }[] = vesseltypes.map(
    (type) => {
      return { id: type.id, amount: 0 };
    }
  );

  // amount of vessels per type
  vessels.forEach((vessel) => {
    const typeIndex = vesselsPerType.findIndex(
      (type) => type.id === vessel.vesselLabel
    );

    vesselsPerType[typeIndex].amount++;
  });

  let companies = [
    "Bennett, Jones and Miller",
    "Roth, Logan and Moreno",
    "Taylor-Sawyer",
    "Murphy and Sons",
    "Rivera Group",
    "Thompson-Padilla",
    "Lane Group",
    "Mooney, Stevenson and Miller",
    "Ballard-Bailey",
    "Brown-Haas",
    "Parks Ltd",
    "Saltwater Sisters Company Marine",
    "Stewart, Perkins and Sanchez",
    "Nielsen, Valentine and Bell",
    "Garcia-Wallace",
    "Smith, Davis and Acosta",
    "FlounderLeska Marine BV",
    "Mccormick Group",
    "ScaniaSeafood Holdings Ltd. Liability Co",
    "Dyer-Campbell",
    "Mendez-Tyler",
    "MonarchCreek Shipping S.p.A.",
    "Jacobson Inc",
    "Weaver-Baker",
    "Smith, Collins and Johnson",
    "George-Brown",
    "Bowers Group",
    "Cisneros-Meyer",
    "Gomez-Mccormick",
    "Green, Vincent and Thompson",
    "NortheastSeafood Carriers Ltd",
    "House Group",
    "Rodriguez, Henry and Woodard",
    "WestRiver Shipping KgaA",
    "Miller, Smith and Reed",
    "Brewer-Massey",
    "Robinson, Mcmillan and Watson",
    "Carter, Mcdonald and Miller",
    "Callahan-Green",
    "Solis-Lopez",
    "Terry, Smith and Lyons",
    "Cordova, Ritter and Flores",
    "Rasmussen, Nelson and King",
    "Ritter Ltd",
    "Faulkner, Shaffer and Moyer",
    "Jordan and Sons",
    "Costa de Oro Enterprises",
    "Reid, Thomas and Gill",
    "Schmidt Ltd",
    "Harper and Sons",
    "Mercado, French and Graham",
    "Williams, Horn and Morales",
    "Mcdonald-Jones",
    "Steele PLC",
    "Vasquez-Gonzalez",
    "Brown, Clarke and Martinez",
    "Garcia-Sanchez",
    "Norman LLC",
    "Clark, Hall and Cole",
    "Chase, Long and Anderson",
    "Wolf and Sons",
    "Cox-Donovan",
    "Chavez and Sons",
    "Mullins-Carrillo",
    "Davis, Harris and West",
    "Barnes and Sons",
    "Fischer, Graham and Robinson",
    "Mcpherson-Wright",
    "Wiley LLC",
    "Solis PLC",
    "Brown-Allen",
    "Martin-Case",
    "Newton-Meyer",
    "SamakaDredgeTransport OJSC",
    "Goodman LLC",
    "Tainamarine Fishing Co",
    "Franco, Olson and Long",
    "Bell, Reynolds and Forbes",
    "Lowery-Myers",
    "Decker PLC",
    "Adkins LLC",
    "BaringoAmerica Marine Ges.m.b.H.",
    "Chambers, Hall and Walker",
    "French Ltd",
    "Sutton PLC",
    "Henry-Alvarez",
    "Lake Malawi  Corp United",
    "Cox-Mason",
    "Dry CreekWorldLogistics Ltd. Liability Co",
    "Riley Inc",
    "Osborne and Sons",
    "Hernandez Group",
    "Horne Group",
    "Maacama Ocean Worldwide LLC",
    "Collins, Johnson and Lloyd",
    "Conley, Benton and Miller",
    "Bailey-Mccullough",
    "Sparmans Marine Carriers Oyj",
  ];
  companies.sort();
  companies = ["SouthSeafood Express Corp", ...companies];

  let vesselsPerCompany: { id: string; amount: number }[] = companies.map(
    (company) => {
      return { id: company, amount: 0 };
    }
  );

  //  amount of vessels per company
  vessels.forEach((vessel) => {
    const companyIndex = vesselsPerCompany.findIndex(
      (company) => company.id === vessel.vesselCompany
    );
    if (companyIndex >= 0) {
      vesselsPerCompany[companyIndex].amount++;
    }
  });

  const renderSort = () => {
    let sortedVessels = [...vessels].sort((a, b) =>
      a.vesselID.localeCompare(b.vesselID)
    );

    sortedVessels.unshift(
      sortedVessels.splice(
        sortedVessels
          .map((vessel) => vessel.vesselID)
          .indexOf("snappersnatcher7be"),
        1
      )[0]
    );

    sortedVessels.unshift(
      sortedVessels.splice(
        sortedVessels
          .map((vessel) => vessel.vesselID)
          .indexOf("roachrobberdb6"),
        1
      )[0]
    );

    return (
      <Box
        border={sortAttribute === "similarity" ? "1px solid black" : undefined}
        borderRadius={sortAttribute === "similarity" ? "lg" : undefined}
        p={sortAttribute === "similarity" ? "1" : undefined}
        mr={sortAttribute === "similarity" ? "2" : undefined}
      >
        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            size="sm"
            mr="0.5rem"
          >
            Sort
          </MenuButton>

          <MenuList p="0.5rem">
            <MenuOptionGroup
              defaultValue={sortAttribute}
              type="radio"
              onChange={(id) => {
                console.log(id);
                if (typeof id === "string") {
                  setSortAttribute(id);
                }
              }}
            >
              <MenuItemOption value="similarity">
                Vessel Similarity
              </MenuItemOption>
              <MenuItemOption value="name">Vessel Name</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
        {sortAttribute === "similarity" && (
          <Menu closeOnSelect={false}>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              size="sm"
              mr="0.5rem"
            >
              Similar to Vessel
            </MenuButton>

            <MenuList p="0.5rem">
              <MenuOptionGroup
                defaultValue={chosenVesselIdForSorting}
                type="radio"
                onChange={(id) => {
                  console.log(id);
                  if (typeof id === "string") {
                    setChosenVesselIdForSorting(id);
                  }
                }}
              >
                {sortedVessels.map((vessel) => {
                  return (
                    <MenuItemOption
                      value={vessel.vesselID}
                      key={vessel.vesselID}
                    >
                      {vessel.vesselID === "snappersnatcher7be" ||
                      vessel.vesselID === "roachrobberdb6" ? (
                        <Tag colorScheme={"red"}>{vessel.vesselName}</Tag>
                      ) : (
                        vessel.vesselName
                      )}
                    </MenuItemOption>
                  );
                })}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        )}
      </Box>
    );
  };

  const renderVesselType = () => {
    const renderOption = (option: { id: string; label: string }) => {
      return (
        <MenuItemOption
          key={option.id}
          value={option.id}
          isChecked={vesselTypeInternal.includes(option.id)}
        >
          {option.label}
          <Tag colorScheme="blue" ml="0.25rem">
            {vesselsPerType.find((type) => type.id === option.id)?.amount}
          </Tag>
        </MenuItemOption>
      );
    };

    return (
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          mr="0.5rem"
        >
          <HStack>
            <>Vessel Type</>

            {vesselTypeInternal.length >= 1 && (
              <Tag colorScheme="blue">{vesselTypeInternal.length}</Tag>
            )}
          </HStack>
        </MenuButton>

        <MenuList p="0.5rem">
          <Button
            onClick={() => {
              setVesselTypeInternal([]);
            }}
          >
            Clear
          </Button>

          <MenuDivider />

          <MenuOptionGroup
            value={vesselTypeInternal}
            defaultValue={vesselTypeInternal}
            type="checkbox"
            onChange={(ids) => {
              setVesselTypeInternal([...ids]);
            }}
          >
            {vesseltypes.map((option) => renderOption(option))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  };

  const renderCompany = () => {
    const renderOption = (option: string) => {
      return (
        <MenuItemOption
          key={option}
          value={option}
          isChecked={companyInternal.includes(option)}
        >
          {option}

          {option === "SouthSeafood Express Corp" ? (
            <Tooltip label={"Bad Guys"} fontSize="sm">
              <Tag colorScheme="red" ml="0.25rem">
                {
                  vesselsPerCompany.find(
                    (company) => company.id === "SouthSeafood Express Corp"
                  )?.amount
                }
              </Tag>
            </Tooltip>
          ) : (
            <Tag colorScheme="blue" ml="0.25rem">
              {
                vesselsPerCompany.find((company) => company.id === option)
                  ?.amount
              }
            </Tag>
          )}
        </MenuItemOption>
      );
    };

    return (
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          mr="0.5rem"
        >
          <HStack>
            <>Company</>

            {companyInternal.length >= 1 && (
              <Tag colorScheme="blue">{companyInternal.length}</Tag>
            )}
          </HStack>
        </MenuButton>
        <MenuList p="0.5rem">
          <Button
            onClick={() => {
              setCompanyInternal([]);
            }}
          >
            Clear
          </Button>

          <MenuDivider />

          <MenuOptionGroup
            value={companyInternal}
            defaultValue={companyInternal}
            type="checkbox"
            onChange={(ids) => {
              setCompanyInternal([...ids]);
            }}
          >
            {companies.map((company: string) => renderOption(company))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  };

  const renderMonth = () => {
    const renderOption = (option: { id: string; label: string }) => {
      return (
        <MenuItemOption
          key={option.id}
          value={option.id}
          isChecked={monthInternal === option.id}
        >
          {option.label}
        </MenuItemOption>
      );
    };

    return (
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          mr="0.5rem"
        >
          <HStack>
            <>Month for Cluster</>

            {monthInternal && (
              <Tag colorScheme="blue">
                {months.find((month) => month.id === monthInternal)?.label}
              </Tag>
            )}
          </HStack>
        </MenuButton>
        <MenuList p="0.5rem">
          <Button
            onClick={() => {
              setMonthInternal("");
            }}
          >
            Clear
          </Button>

          <MenuDivider />

          <MenuOptionGroup
            value={monthInternal}
            defaultValue={monthInternal}
            type="radio"
            onChange={(id: string | string[]) => {
              if (typeof id === "string") {
                setMonthInternal(id);
              }
            }}
          >
            {months.map((month: { id: string; label: string }) =>
              renderOption(month)
            )}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  };

  const isRoachrobberInCluster = (clusterId: string) => {
    const roachrobberClusters: { id: string; cluster: string }[] = [
      { id: "2_february", cluster: "3" },
      { id: "3_march", cluster: "4" },
      { id: "4_april", cluster: "1" },
      { id: "5_may", cluster: "2" },
      { id: "6_june", cluster: "3" },
      { id: "7_july", cluster: "3" },
      { id: "8_august", cluster: "8" },
      { id: "9_september", cluster: "4" },
      { id: "10_october", cluster: "3" },
      { id: "11_november", cluster: "1" },
    ];

    if (
      roachrobberClusters.find((month) => month.id === monthInternal)
        ?.cluster === clusterId
    )
      return "Roach Robber";
    else return false;
  };

  const isSnappersnatcherInCluster = (clusterId: string) => {
    const snappersnatcherClusters: { id: string; cluster: string }[] = [
      { id: "2_february", cluster: "1" },
      { id: "3_march", cluster: "2" },
      { id: "4_april", cluster: "2" },
      { id: "5_may", cluster: "1" },
      { id: "6_june", cluster: "3" },
      { id: "7_july", cluster: "3" },
      { id: "8_august", cluster: "8" },
      { id: "9_september", cluster: "4" },
      { id: "10_october", cluster: "3" },
      { id: "11_november", cluster: "1" },
    ];

    if (
      snappersnatcherClusters.find((month) => month.id === monthInternal)
        ?.cluster === clusterId
    )
      return "Snappersnatcher";
    else return false;
  };

  const renderCluster = () => {
    const renderOption = (option: string) => {
      return (
        <MenuItemOption
          key={option}
          value={option}
          isChecked={clusterInternal.includes(option)}
        >
          {option}

          {isRoachrobberInCluster(option) ||
          isSnappersnatcherInCluster(option) ? (
            <Tooltip
              label={`${isRoachrobberInCluster(option) ? "Roach Robber" : ""} ${
                isSnappersnatcherInCluster(option) ? "Snapper Snatcher" : ""
              }`}
            >
              <Tag colorScheme={"red"} ml="0.25rem">
                {
                  clusterList.find((cluster) => cluster.id === option)?.vessels
                    .length
                }
              </Tag>
            </Tooltip>
          ) : (
            <Tag colorScheme={"blue"} ml="0.25rem">
              {
                clusterList.find((cluster) => cluster.id === option)?.vessels
                  .length
              }
            </Tag>
          )}
        </MenuItemOption>
      );
    };

    return monthInternal ? (
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          mr="0.5rem"
        >
          <HStack>
            <>Cluster</>

            {clusterInternal.length >= 1 && (
              <Tag colorScheme="blue">{clusterInternal.length}</Tag>
            )}
          </HStack>
        </MenuButton>
        <MenuList p="0.5rem">
          <Button
            onClick={() => {
              setClusterInternal([]);
            }}
          >
            Clear
          </Button>

          <MenuDivider />

          <MenuOptionGroup
            value={clusterInternal}
            defaultValue={clusterInternal}
            type="checkbox"
            onChange={(ids) => {
              setClusterInternal([...ids]);
            }}
          >
            {clusterList.map((cluster: cluster) => renderOption(cluster.id))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    ) : null;
  };

  const uniqueVesselIds = vessels.map((vessel) => vessel.vesselID);

  const renderVesselIds = () => {
    const renderOption = (option: string) => (
      <MenuItemOption
        key={option}
        value={option}
        isChecked={vesselIdsInternal.includes(option)}
      >
        {option === "snappersnatcher7be" || option === "roachrobberdb6" ? (
          <Tag colorScheme={"red"} ml="0.25rem">
            {option}
          </Tag>
        ) : (
          option
        )}
      </MenuItemOption>
    );

    const validVesselIds = uniqueVesselIds.filter(
      (vesselId) => vesselId != null
    );

    const sortedVesselIds = validVesselIds.sort((a, b) => {
      if (a === "snappersnatcher7be" || a === "roachrobberdb6") return -1;
      if (b === "snappersnatcher7be" || b === "roachrobberdb6") return 1;
      return a.localeCompare(b);
    });

    return (
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          mr="0.5rem"
        >
          <HStack>
            <>Vessel ID</>
            {vesselIdsInternal.length >= 1 && (
              <Tag colorScheme="blue">{vesselIdsInternal.length}</Tag>
            )}
          </HStack>
        </MenuButton>
        <MenuList p="0.5rem">
          <Button
            onClick={() => {
              setVesselIdsInternal([]);
            }}
          >
            Clear
          </Button>
          <MenuDivider />
          <MenuOptionGroup
            value={vesselIdsInternal}
            defaultValue={vesselIdsInternal}
            type="checkbox"
            onChange={(ids) => {
              setVesselIdsInternal([...ids]);
            }}
          >
            {sortedVesselIds.map((vesselId: string) => renderOption(vesselId))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  };

  useEffect(() => {
    const filters = {
      sortAttribute: sortAttribute,
      chosenVesselIdForSorting: chosenVesselIdForSorting,
      vesseltype: vesselType,
      company: company,
      vesselId: vesselIds,
      month: month,
      cluster: cluster,
    };

    handleSetFilters(filters);
  }, [
    sortAttribute,
    chosenVesselIdForSorting,
    vesselType,
    company,
    vesselIds,
    month,
    cluster,
  ]);

  return (
    <Accordion allowToggle mb="0.5rem">
      <AccordionItem pl="0">
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Filter
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>

        <AccordionPanel pb={"0.5rem"} pl="0">
          <Flex
            width="100%"
            justifyContent="flex-start"
            alignItems="center"
            wrap="nowrap"
            flexDirection="row"
          >
            {renderSort()}
            {renderVesselType()}
            {renderCompany()}
            {renderVesselIds()}
            <Box borderRadius="lg" border={"1px solid black"} p={"1"}>
              {renderMonth()}
              {renderCluster()}
            </Box>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default PixelFilterBox;
