import React, { useState } from "react";
import {
  ChakraProvider,
  Box,
  HStack,
  ButtonGroup,
  IconButton,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
} from "@chakra-ui/react";
import { FaBarcode, FaAdjust } from "react-icons/fa";
import { PlausibilityMap } from "./PlausibilityMap";
import "./PlausibilityMapView.css";

interface PlausibilityMapViewProps {
  selectedObject: any;
}

const fishInfo = [
  { name: "Cod/Gadus n.specificatae", fishing: "allowed" },
  { name: "Birdseye/Pisces frigus", fishing: "ambiguous" },
  { name: "Sockfish/Pisces foetida", fishing: "forbidden" },
  { name: "Wrasse/Labridae n.refert", fishing: "ambiguous" },
  { name: "Beauvoir/Habeas pisces", fishing: "ambiguous" },
  { name: "Harland/Piscis sapidum", fishing: "allowed" },
  { name: "Tuna/Thunnini n.vera", fishing: "ambiguous" },
  { name: "Offidiaa/Piscis osseus", fishing: "forbidden" },
  { name: "Salmon/Oncorhynchus rosea", fishing: "forbidden" },
  { name: "Sockfish/Pisces foetida", fishing: "forbidden" },
  { name: "Helenaa/Pisces satis", fishing: "forbidden" },
];

export const PlausibilityMapView: React.FC<PlausibilityMapViewProps> = ({
  selectedObject,
}) => {
  const [viewMode, setViewMode] = useState<"default" | "highlight">("default");
  const [width, setWidth] = useState<number>(500);
  const [height, setHeight] = useState<number>(500);

  const handleWidthChange = (value: number) => {
    setWidth(value);
  };

  const handleHeightChange = (value: number) => {
    setHeight(value);
  };
  const toggleViewMode = (mode: "default" | "highlight") => {
    setViewMode(mode);
  };

  return (
    <ChakraProvider>
      <Box p="4" bg="gray.100" borderRadius="md" mb="4">
        <Flex justifyContent="space-between">
          <Flex flex={6}>
            <ButtonGroup size="sm" isAttached variant="outline">
              <IconButton
                icon={<FaBarcode />}
                isActive={viewMode === "default"}
                aria-label="Default view"
                onClick={() => toggleViewMode("default")}
              />
              <IconButton
                icon={<FaAdjust />}
                isActive={viewMode === "highlight"}
                aria-label="Highlight view"
                onClick={() => toggleViewMode("highlight")}
              />
            </ButtonGroup>
            {selectedObject && (
              <HStack spacing="12px">
                <Text fontWeight="bold">Type:</Text>
                <Text>{selectedObject.type}</Text>
                <Text fontWeight="bold">Selection:</Text>
                <Text>{selectedObject.selection}</Text>
              </HStack>
            )}
          </Flex>
          <Flex flex={4} justifyContent="space-between">
            <HStack spacing="12px" flex={5}>
              <Text fontWeight="bold"> Width:</Text>
              <Text>{width}</Text>
              <Slider
                defaultValue={500}
                min={500}
                max={10000}
                step={50}
                width="75px"
                onChange={handleWidthChange}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </HStack>
            <HStack spacing="12px" flex={5}>
              <Text fontWeight="bold"> Height:</Text>
              <Text>{height}</Text>
              <Slider
                defaultValue={500}
                min={500}
                max={10000}
                step={50}
                width="75px"
                onChange={handleHeightChange}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </HStack>
          </Flex>
        </Flex>
      </Box>
      <Box p="2" style={{ overflowX: "auto" }}>
        {!selectedObject ? (
          <Box textAlign="center" mt="4">
            <Text fontSize="xl" color="blue.500">
              Please select either a row or a colum from the PieGrid first
            </Text>
          </Box>
        ) : (
          <PlausibilityMap
            width={width}
            height={height}
            data={selectedObject}
            viewMode={viewMode}
            fishInfo={fishInfo}
          />
        )}
      </Box>
    </ChakraProvider>
  );
};
