import {
  Box,
  Heading,
  Text,
  Tag,
  Flex,
  Tooltip,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { Canvas } from "./Canvas";
import "./PixelVizView.css";
import { Reorder } from "framer-motion";
import { colorToLocation, colorMap2dGuo } from "../../support/colors";
import { useState, useEffect, memo } from "react";

import { locationId } from "./mock_new";

import { BACKEND_URL, PixelData, Vessel } from "../../App";
import React from "react";

const getColorPerPixel = (locationId: locationId) => {
  const color = colorMap2dGuo.find(
    (colorMapping) => colorMapping.location_id === locationId
  );

  // if (!color) console.log(locationId);

  return color
    ? color
    : {
        location_id: "error",
        color: "red",
      };
};

export type VesselPixelVizProps = {
  vesselData: Vessel | undefined;
  vesselCluster: string | undefined;
};

export const VesselPixelViz = React.memo(
  ({ vesselData, vesselCluster }: VesselPixelVizProps) => {
    const [pixelVizData, setPixelVizData] = useState<PixelData>();

    const getColorArray = (location_ids_array: locationId[]) => {
      return location_ids_array?.map((location: locationId) => {
        return getColorPerPixel(location);
      });
    };

    const getDay = (cellNumber: number) => {
      const reducedNumber = cellNumber % 7;

      const weekdays = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];

      return weekdays[reducedNumber];
    };

    const getMonth = (cellNumber: number) => {
      const reducedNumber = Math.floor(cellNumber / 7);

      const months = [
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "Oktober",
        "November",
      ];

      return months[reducedNumber];
    };

    let columns = Array(77).fill("a");
    columns = columns.map((column, index) => getDay(index));

    const rows = ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"];

    const getLocationIdPart = (row: number, column: number) => {
      // get only relevant columns
      let part = pixelVizData?.location_ids.map((row) =>
        row.slice(column * 40, (column + 1) * 40)
      );
      // get only relevant rows
      part = part?.slice(row * 44, (row + 1) * 44);

      part = part?.map((row) => [...new Set(row)]);

      let flatPart = part?.flat();
      flatPart = [...new Set(flatPart)];

      // join array to string & delete duplicates
      let joinedRows = flatPart.join(", ");

      return joinedRows;
    };

    const renderRows = () => {
      if (pixelVizData?.location_ids && vesselData)
        return (
          <Box position={"relative"}>
            <VStack gap="0" position={"absolute"}>
              {rows.map((row, rowIndex) => (
                <HStack w="3080px" gap="0" key={row + rowIndex}>
                  {columns.map((column, columnIndex) => (
                    <Tooltip
                      hasArrow
                      label={
                        <>
                          {vesselData.vesselName}
                          <br />
                          {getMonth(columnIndex)}
                          <br />
                          {column + ", " + row}
                          <br />
                          {getLocationIdPart(rowIndex, columnIndex)}
                        </>
                      }
                      fontSize="sm"
                      key={column + columnIndex}
                    >
                      <Box w={"40px"} h={"44px"}>
                        {}
                      </Box>
                    </Tooltip>
                  ))}
                </HStack>
              ))}
            </VStack>

            <Canvas
              key={vesselData.vesselID}
              height={pixelVizData?.location_ids?.length}
              width={3080}
              colors={pixelVizData?.location_ids?.map((row) => {
                return getColorArray(row);
              })}
            />
          </Box>
        );
    };

    const fetchPixelVizData = () => {
      fetch(
        `${BACKEND_URL}/get_recurrent_pixels?vessel_id=${vesselData?.vesselID}&layout=vessel_compare`
      )
        .then((response) => {
          return response.text();
        })
        .then((data) => JSON.parse(data))
        .then((data) => {
          setPixelVizData(data.recurrent_pixels);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    // fetch pixelviz data once vessel data are there
    useEffect(() => {
      if (vesselData) fetchPixelVizData();
    }, []);

    const getColorScheme = (vessel: Vessel) => {
      return vessel?.vesselID === "roachrobberdb6" ||
        vessel?.vesselID === "snappersnatcher7be"
        ? "red"
        : undefined;
    };

    return vesselData ? (
      <Flex
        gap={"0.5rem"}
        as={Reorder.Item}
        value={vesselData}
        p={1}
        cursor="grab"
        whileTap={{ cursor: "grabbing" }}
        overflow={"scroll"}
      >
        <Box minWidth="9rem">
          <Tooltip
            label={
              <>
                {`Name: ` + vesselData.vesselName}
                <br />
                {`Company: ` + vesselData.vesselCompany}
                <br />
                {`Type: ` + vesselData.vesselLabel}
                <br />
                {`Cluster: ` + vesselCluster}
              </>
            }
            fontSize="sm"
          >
            <Tag colorScheme={getColorScheme(vesselData)} size="sm">
              {vesselData.vesselName}
            </Tag>
          </Tooltip>
        </Box>

        <Box>{renderRows()}</Box>
      </Flex>
    ) : null;
  }
);
