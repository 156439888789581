export type colorToLocation = { location_id: string | null; color: string };

export const colorMap2dGuo: colorToLocation[] = [
  {
    location_id: "none",
    color: "white",
  },
  {
    location_id: null,
    color: "white",
  },
  {
    location_id: "collision",
    color: "black",
  },
  {
    location_id: "Nav A",
    color: "#63AA6F",
  },
  {
    location_id: "Nav B",
    color: "#4DABC7",
  },
  {
    location_id: "Nav C",
    color: "#F3ECC4",
  },
  {
    location_id: "Nav D",
    color: "#66BFE3",
  },
  {
    location_id: "Nav E",
    color: "#A7C37F",
  },
  {
    location_id: "Nav 1",
    color: "#F1A47C",
  },
  {
    location_id: "Nav 2",
    color: "#F2ABAD",
  },
  {
    location_id: "Nav 3",
    color: "#F3AFE0",
  },

  {
    location_id: "Haacklee",
    color: "#B4E2F2",
  },
  {
    location_id: "City of Haacklee",
    color: "#B4E2F2",
  },
  {
    location_id: "Port Grove",
    color: "#A0DDE3",
  },
  {
    location_id: "City of Port Grove",
    color: "#A0DDE3",
  },
  {
    location_id: "Lomark",
    color: "#E4F3DB",
  },
  {
    location_id: "City of Lomark",
    color: "#E4F3DB",
  },
  {
    location_id: "City of Himark",
    color: "#BADAAA",
  },
  {
    location_id: "Paackland",
    color: "#F4BACE",
  },
  {
    location_id: "City of Paackland",
    color: "#F4BACE",
  },
  {
    location_id: "Centralia",
    color: "#F7CCE3",
  },
  {
    location_id: "South Paackland",
    color: "#F6C5E9",
  },
  {
    location_id: "City of South Paackland",
    color: "#F6C5E9",
  },
  {
    location_id: "Exit West",
    color: "#2F6E5D",
  },

  {
    location_id: "Exit East",
    color: "#A42073",
  },
  {
    location_id: "Exit South",
    color: "#3D4AC2",
  },
  {
    location_id: "Exit North",
    color: "#726B1F",
  },
  // {
  //   location_id: "Cod Table",
  //   color: "#DD5A87",
  // },
  {
    location_id: "Cod Table",
    color: "yellow",
  },
  // {
  //   location_id: "Ghoti Preserve",
  //   color: "#F5C8B5",
  // },
  {
    location_id: "Ghoti Preserve",
    color: "red",
  },
  // {
  //   location_id: "Wrasse Beds",
  //   color: "#FEF8F4",
  // },
  {
    location_id: "Wrasse Beds",
    color: "yellow",
  },
  // {
  //   location_id: "Nemo Reef",
  //   color: "#E3E1FB",
  // },
  {
    location_id: "Nemo Reef",
    color: "red",
  },
  // {
  //   location_id: "Don Limpet Preserve",
  //   color: "#84CEBF",
  // },
  {
    location_id: "Don Limpet Preserve",
    color: "red",
  },
  // {
  //   location_id: "Tuna Shelf",
  //   color: "#C1B2F5",
  // },
  {
    location_id: "Tuna Shelf",
    color: "yellow",
  },
];

export const colorMap2dGuoForMap: colorToLocation[] = [
  {
    location_id: "none",
    color: "white",
  },
  {
    location_id: null,
    color: "white",
  },
  {
    location_id: "collision",
    color: "black",
  },
  {
    location_id: "Nav A",
    color: "#63AA6F",
  },
  {
    location_id: "Nav B",
    color: "#4DABC7",
  },
  {
    location_id: "Nav C",
    color: "#F3ECC4",
  },
  {
    location_id: "Nav D",
    color: "#66BFE3",
  },
  {
    location_id: "Nav E",
    color: "#A7C37F",
  },
  {
    location_id: "Nav 1",
    color: "#F1A47C",
  },
  {
    location_id: "Nav 2",
    color: "#F2ABAD",
  },
  {
    location_id: "Nav 3",
    color: "#F3AFE0",
  },

  {
    location_id: "Haacklee",
    color: "#B4E2F2",
  },
  {
    location_id: "City of Haacklee",
    color: "#B4E2F2",
  },
  {
    location_id: "Port Grove",
    color: "#A0DDE3",
  },
  {
    location_id: "City of Port Grove",
    color: "#A0DDE3",
  },
  {
    location_id: "Lomark",
    color: "#E4F3DB",
  },
  {
    location_id: "City of Lomark",
    color: "#E4F3DB",
  },
  {
    location_id: "City of Himark",
    color: "#BADAAA",
  },
  {
    location_id: "Paackland",
    color: "#F4BACE",
  },
  {
    location_id: "City of Paackland",
    color: "#F4BACE",
  },
  {
    location_id: "Centralia",
    color: "#F7CCE3",
  },
  {
    location_id: "South Paackland",
    color: "#F6C5E9",
  },
  {
    location_id: "City of South Paackland",
    color: "#F6C5E9",
  },
  {
    location_id: "Exit West",
    color: "#2F6E5D",
  },

  {
    location_id: "Exit East",
    color: "#A42073",
  },
  {
    location_id: "Exit South",
    color: "#3D4AC2",
  },
  {
    location_id: "Exit North",
    color: "#726B1F",
  },
  // {
  //   location_id: "Cod Table",
  //   color: "#DD5A87",
  // },
  {
    location_id: "Cod Table",
    color: "yellow",
  },
  // {
  //   location_id: "Ghoti Preserve",
  //   color: "#F5C8B5",
  // },
  {
    location_id: "Ghoti Preserve",
    color: "red",
  },
  // {
  //   location_id: "Wrasse Beds",
  //   color: "#FEF8F4",
  // },
  {
    location_id: "Wrasse Beds",
    color: "yellow",
  },
  // {
  //   location_id: "Nemo Reef",
  //   color: "#E3E1FB",
  // },
  {
    location_id: "Nemo Reef",
    color: "red",
  },
  // {
  //   location_id: "Don Limpet Preserve",
  //   color: "#84CEBF",
  // },
  {
    location_id: "Don Limpet Preserve",
    color: "red",
  },
  // {
  //   location_id: "Tuna Shelf",
  //   color: "#C1B2F5",
  // },
  {
    location_id: "Tuna Shelf",
    color: "yellow",
  },
];
