import { Box, Heading, List, Tag, Flex, Tooltip } from "@chakra-ui/react";
import "./PixelVizView.css";
import { Reorder } from "framer-motion";
import { useState, useEffect, memo } from "react";
import PixelFilterBox from "./PixelFilterBox";

import { BACKEND_URL, Vessel } from "../../App";
import React from "react";
import { VesselPixelViz } from "./VesselPixelViz";

export type appliedFilters = {
  sortAttribute: string;
  chosenVesselIdForSorting: string | undefined;
  vesseltype: string[];
  company: string[];
  vesselId: string[];
  month: string | undefined;
  cluster: string[];
} | null;

export type PixelViewProps = {
  vesselData: Vessel[];
};

export type cluster = { id: string; vessels: string[] };

export type clusterPerMonth = {
  month: string;
  clusters: cluster[];
};

export const PixelVizView = React.memo(({ vesselData }: PixelViewProps) => {
  const [vesselsOrder, setVesselsOrder] = useState<string[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<appliedFilters>(null);
  const [vesselsWithClusterIds, setVesselsWithClusterIds] = useState<
    { id: string; clusterId: string }[]
  >([]);

  const [clusterDataPerMonth, setClusterDataPerMonth] = useState<
    clusterPerMonth[]
  >([]);

  const handleSetFilters = (filters: appliedFilters) => {
    setAppliedFilters(filters);
  };

  const getVesselWithData = (vesselId: string) => {
    return vesselData.find((vessel) => vessel.vesselID === vesselId);
  };

  const getVesselWithCluster = (vesselId: string) => {
    const appliedMonth = clusterDataPerMonth.find(
      (data) => data.month === appliedFilters?.month
    );

    let clusterThatIncludesVessel = appliedMonth?.clusters.find((cluster) =>
      cluster.vessels.includes(vesselId)
    );

    const clusterId = clusterThatIncludesVessel?.id || "null";

    let vesselWithCluster = {
      id: vesselId,
      clusterId: clusterId,
    };

    return vesselWithCluster;
  };
  // /api/get_vessels_order?vessel_id=wavewranglerc2d

  const getSortedandFilteredData = (data: string[]) => {
    const filterByCompany = (data: string[]) => {
      const filtered = data.filter((vesselId) => {
        const vesselWithData = getVesselWithData(vesselId);

        return vesselWithData?.vesselCompany
          ? appliedFilters?.company.includes(vesselWithData?.vesselCompany)
          : null;
      });

      return filtered;
    };

    const filterByType = (data: string[]) => {
      const filtered = data.filter((vesselId) => {
        const vesselWithData = getVesselWithData(vesselId);

        return vesselWithData?.vesselLabel
          ? appliedFilters?.vesseltype.includes(vesselWithData?.vesselLabel)
          : null;
      });

      return filtered;
    };

    const filterById = (data: string[]) => {
      const filtered = data.filter((vesselId) => {
        const vesselWithData = getVesselWithData(vesselId);

        return vesselWithData?.vesselID
          ? appliedFilters?.vesselId.includes(vesselWithData?.vesselID)
          : null;
      });
      console.log(filtered);
      return filtered;
    };

    const filterByCluster = (data: string[]) => {
      const filtered = data.filter((vesselId) => {
        const vesselCluster = getVesselWithCluster(vesselId);

        return vesselCluster
          ? appliedFilters?.cluster.includes(vesselCluster.clusterId)
          : null;
      });

      return filtered;
    };

    let returnData = data;

    if (
      appliedFilters?.vesseltype !== undefined &&
      appliedFilters?.vesseltype?.length >= 1
    ) {
      returnData = filterByType(returnData);
    }

    if (
      appliedFilters?.company !== undefined &&
      appliedFilters?.company?.length >= 1
    ) {
      returnData = filterByCompany(returnData);
    }

    if (
      appliedFilters?.vesselId !== undefined &&
      appliedFilters?.vesselId?.length >= 1
    ) {
      returnData = filterById(returnData);
    }

    if (
      appliedFilters?.month !== undefined &&
      appliedFilters?.cluster !== undefined &&
      appliedFilters?.cluster?.length >= 1
    ) {
      returnData = filterByCluster(returnData);
    }

    returnData = [...new Set(returnData)];

    return returnData;
  };

  const fetchClusterData = (month: string) => {
    fetch(`${BACKEND_URL}/cluster?type=${month}`)
      .then((response) => {
        return response.text();
      })
      .then((data) => JSON.parse(data))
      .then((data) => {
        const vesselsWithClusterIds = Object.entries(data.clusters).map(
          (entry) => {
            const id = entry[0];
            const clusterId = `${entry[1]}`;
            return { id, clusterId };
          }
        );

        setVesselsWithClusterIds(vesselsWithClusterIds);

        const emptyClusters = [
          ...new Set(
            Object.values(vesselsWithClusterIds).map((entry) => entry.clusterId)
          ),
        ];
        emptyClusters.sort(new Intl.Collator("de", { numeric: true }).compare);

        const getClusterById = (clusterId: string) => {
          const vessels: string[] = [];

          vesselsWithClusterIds.forEach((vessel) => {
            if (vessel.clusterId === clusterId) vessels.push(vessel.id);
          });

          return { id: clusterId, vessels: vessels };
        };

        const clustersById = emptyClusters.map((emptyCluster) => {
          return getClusterById(emptyCluster);
        });

        let newClusterDataPerMonth = clusterDataPerMonth;
        const monthIndex = clusterDataPerMonth.findIndex(
          (monthData) => monthData.month === month
        );

        if (monthIndex > -1) {
          newClusterDataPerMonth[monthIndex] = {
            month: month,
            clusters: clustersById,
          };
        } else
          newClusterDataPerMonth.push({
            month: month,
            clusters: clustersById,
          });

        newClusterDataPerMonth.sort((a, b) => {
          return a.month.localeCompare(b.month, undefined, {
            sensitivity: "base",
            numeric: true,
          });
        });

        setClusterDataPerMonth(newClusterDataPerMonth);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => console.log("cluster data fetched"));
  };

  const fetchSortingData = (vesselId: string) => {
    fetch(`${BACKEND_URL}/get_vessels_order?vessel_id=${vesselId}`)
      .then((response) => {
        return response.text();
      })
      .then((data) => JSON.parse(data))
      .then((data) => {
        setVesselsOrder(data.vessels_order);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // .finally(() => console.log("cluster data fetched"));
  };

  const sortBySimilarity = () => {
    if (appliedFilters?.chosenVesselIdForSorting) {
      fetchSortingData(appliedFilters?.chosenVesselIdForSorting);
    }
  };

  useEffect(() => {
    setVesselsOrder(vesselData.map((vessel) => vessel.vesselID));
  }, [vesselData]);

  useEffect(() => {
    if (appliedFilters?.month && appliedFilters.month) {
      // only fetch data if the month is called for the first time
      if (
        !clusterDataPerMonth.find(
          (month: clusterPerMonth) => month.month === appliedFilters.month
        )
      )
        fetchClusterData(appliedFilters.month);
    }

    if (appliedFilters?.chosenVesselIdForSorting !== undefined) {
      sortBySimilarity();
    }
  }, [appliedFilters]);

  const getClusterListForFilter = () => {
    const month = clusterDataPerMonth.find(
      (month) => month.month === appliedFilters?.month
    );
    return month?.clusters || [];
  };

  const renderViz = () => {
    const isAFilterApplied =
      appliedFilters?.month ||
      (appliedFilters?.company && appliedFilters?.company?.length > 0) ||
      (appliedFilters?.vesselId && appliedFilters?.vesselId?.length > 0) ||
      (appliedFilters?.vesseltype && appliedFilters?.vesseltype?.length > 0);

    const ifMonthThenAlsoCluster =
      !appliedFilters?.month ||
      (appliedFilters?.month &&
        clusterDataPerMonth.find(
          (entry: clusterPerMonth) => entry.month === appliedFilters?.month
        ));

    return isAFilterApplied && ifMonthThenAlsoCluster ? (
      getSortedandFilteredData(vesselsOrder)?.map((vessel, index) => (
        <VesselPixelViz
          key={vessel}
          vesselData={getVesselWithData(vessel)}
          vesselCluster={
            vesselsWithClusterIds.find(
              (vesselWithCluster) => vesselWithCluster.id === vessel
            )?.clusterId
          }
        ></VesselPixelViz>
      ))
    ) : (
      <>Please choose at least one filter to show data</>
    );
  };

  return (
    <Box>
      <PixelFilterBox
        vessels={vesselData}
        handleSetFilters={handleSetFilters}
        clusterList={getClusterListForFilter()}
      />

      {vesselData && vesselsOrder && (
        <List
          as={Reorder.Group}
          axis="y"
          values={vesselsOrder}
          onReorder={(newOrder: any) => {
            setVesselsOrder(newOrder);
          }}
        >
          {renderViz()}
        </List>
      )}
    </Box>
  );
});
