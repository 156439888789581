import React, { useState, useEffect } from "react";
import { Box, Tag, Tooltip } from "@chakra-ui/react";
import { colorMap2dGuo } from "../../support/colors";
import { BACKEND_URL, Vessel } from "../../App";
import { MapVessel } from "./SequenceView";
import {
  FaCuttlefish,
  FaCircle,
  FaAsterisk,
  FaBookOpen,
  FaBolt,
} from "react-icons/fa";

// type ItemWithLocations = {
//   [key: string]: string[];
// };

type ItemWithLocations = string[];

interface SequenceProps {
  viewMode: "tag" | "icon";
  vessel: Vessel;
}

export const Sequence = ({ viewMode, vessel }: SequenceProps) => {
  const [mapVesselData, setMapVesselData] = useState<MapVessel[]>([]);

  const getColorForLocation = (location: string): string => {
    const locationColor = colorMap2dGuo.find(
      (colorMapping) => colorMapping.location_id === location
    );
    return locationColor ? locationColor.color : "white";
  };

  // Assign simple icons to each location
  const locationIcons: { [key: string]: JSX.Element } = {
    none: <FaBolt />,
    collision: <FaBolt />,
    "Nav A": <FaCircle />,
    "Nav B": <FaCircle />,
    "Nav C": <FaCircle />,
    "Nav D": <FaCircle />,
    "Nav E": <FaCircle />,
    "Nav 1": <FaCircle />,
    "Nav 2": <FaCircle />,
    "Nav 3": <FaCircle />,
    Haacklee: <FaBookOpen />,
    "City of Haacklee": <FaCuttlefish />,
    "Port Grove": <FaBookOpen />,
    "City of Port Grove": <FaCuttlefish />,
    Lomark: <FaBookOpen />,
    "City of Lomark": <FaCuttlefish />,
    "City of Himark": <FaCuttlefish />,
    Paackland: <FaBookOpen />,
    "City of Paackland": <FaCuttlefish />,
    "South Paackland": <FaBookOpen />,
    "City of South Paackland": <FaCuttlefish />,
    "Exit West": <FaCircle />,
    "Exit East": <FaCircle />,
    "Exit South": <FaCircle />,
    "Exit North": <FaCircle />,
    "Cod Table": <FaBookOpen />,
    "Ghoti Preserve": <FaAsterisk />,
    "Wrasse Beds": <FaBookOpen />,
    "Nemo Reef": <FaAsterisk />,
    "Don Limpet Preserve": <FaAsterisk />,
    "Tuna Shelf": <FaBookOpen />,
  };

  const fetchItemData = () => {
    const startTime = "0000-01-01T08:48:37.586351000Z";
    const endTime = "9999-03-31T09:07:38";

    fetch(
      `${BACKEND_URL}/mapVessel?vesselID=${
        vessel.vesselID
      }&startTime=${encodeURIComponent(startTime)}&endTime=${encodeURIComponent(
        endTime
      )}`
    )
      .then((response) => {
        return response.text();
      })
      .then((data) => JSON.parse(data))
      .then((data) => {
        setMapVesselData(data.mapVessel);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    console.log("fetching data");
    fetchItemData();
  }, []);

  return (
    mapVesselData &&
    (viewMode === "tag" ? (
      <Box key={vessel.vesselID} display="box" alignItems="center" mb={2}>
        {mapVesselData?.map((location, index) => (
          <Tag
            key={index}
            mr={1}
            backgroundColor={getColorForLocation(location.locationID)}
            whiteSpace="nowrap"
            size="md"
          >
            {location.locationName}
          </Tag>
        ))}
      </Box>
    ) : (
      <Box key={vessel.vesselID} display="flex" alignItems="center" mb={2}>
        {mapVesselData.map((location, index) => (
          <Tooltip key={index} label={location.locationName}>
            <Box
              mr={1}
              width="24px"
              height="24px"
              color={getColorForLocation(location.locationID)}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="4px"
            >
              {locationIcons[location.locationID]}
            </Box>
          </Tooltip>
        ))}
      </Box>
    ))
  );
};
