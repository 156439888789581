export type colorToCluster = { cluster: number | null; color: string };

export const colorMap2Cluster: colorToCluster[] = [
  { cluster: 1, color: "#63AA6F" },
  { cluster: 2, color: "#FF5733" },
  { cluster: 3, color: "#33C1FF" },
  { cluster: 4, color: "#FFC300" },
  { cluster: 5, color: "#DAF7A6" },
  { cluster: 6, color: "#581845" },
  { cluster: 7, color: "#C70039" },
  { cluster: 8, color: "#900C3F" },
  { cluster: 9, color: "#1C1C1C" },
  { cluster: 10, color: "#2E86C1" },
  { cluster: 11, color: "#8E44AD" },
  { cluster: 12, color: "#F4D03F" },
  { cluster: 13, color: "#7D3C98" },
  { cluster: 14, color: "#A93226" },
  { cluster: 15, color: "#2980B9" },
  { cluster: 16, color: "#1ABC9C" },
  { cluster: 17, color: "#34495E" },
  { cluster: 18, color: "#5D6D7E" },
  { cluster: 19, color: "#AF7AC5" },
  { cluster: 20, color: "#D4E157" },
  { cluster: 21, color: "#2ECC71" },
  { cluster: 22, color: "#F5B041" },
  { cluster: 23, color: "#F1948A" },
  { cluster: 24, color: "#16A085" },
  { cluster: 25, color: "#C0392B" },
];
