import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Tag,
  useControllableState,
  HStack,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import React, { useEffect, useState, useCallback } from "react";
import { BACKEND_URL } from "../../App";
import { colorMap2Cluster } from "../../support/clusters"; // Adjust the import path as necessary

export interface Vessel {
  vesselCompany: string;
  vesselID: string;
  vesselLabel: string;
  vesselName: string;
}

export type cluster = { id: string; vessels: string[] };

export type appliedFilters = {
  vesseltype: string[];
  company: string[];
  vesselIds: string[];
  cluster: string[];
  month: string | undefined;
};

export type FilterBoxProps = {
  vessels: Vessel[];
  handleSetFilters: (filters: appliedFilters) => void;
  setClusterList: (clusters: cluster[]) => void;
};

export const FilterBox: React.FC<FilterBoxProps> = ({
  vessels,
  handleSetFilters,
  setClusterList,
}) => {
  const [vesselType, setVesselType] = useState<string[]>([]);
  const [vesselTypeInternal, setVesselTypeInternal] = useControllableState({
    value: vesselType,
    onChange: setVesselType,
  });

  const [company, setCompany] = useState<string[]>([]);
  const [companyInternal, setCompanyInternal] = useControllableState({
    value: company,
    onChange: setCompany,
  });

  const [vesselIds, setVesselIds] = useState<string[]>([]);
  const [vesselIdsInternal, setVesselIdsInternal] = useControllableState({
    value: vesselIds,
    onChange: setVesselIds,
  });

  const [cluster, setCluster] = useState<string[]>([]);
  const [clusterInternal, setClusterInternal] = useControllableState({
    value: cluster,
    onChange: setCluster,
  });

  const [month, setMonth] = useState<string>();
  const [monthInternal, setMonthInternal] = useControllableState({
    value: month,
    onChange: setMonth,
  });

  const [clusterList, setLocalClusterList] = useState<cluster[]>([]);
  const [filteredClusterList, setFilteredClusterList] = useState<cluster[]>([]);

  let months: { id: string; label: string }[] = [
    { id: "2_february", label: "February" },
    { id: "3_march", label: "March" },
    { id: "4_april", label: "April" },
    { id: "5_may", label: "May" },
    { id: "6_june", label: "June" },
    { id: "7_july", label: "July" },
    { id: "8_august", label: "August" },
    { id: "9_september", label: "September" },
    { id: "10_october", label: "October" },
    { id: "11_november", label: "November" },
  ];

  // Get unique vessel types and companies from the vessels array
  const uniqueVesselTypes = Array.from(
    new Set(vessels.map((vessel) => vessel.vesselLabel))
  );
  const uniqueCompanies = Array.from(
    new Set(vessels.map((vessel) => vessel.vesselCompany))
  );
  const uniqueVesselIds = vessels.map((vessel) => vessel.vesselID);

  // Count items for each type and company
  const countItems = (items: Vessel[], key: keyof Vessel) => {
    return items.reduce<Record<string, number>>((acc, item) => {
      const value = item[key] as string;
      acc[value] = (acc[value] || 0) + 1;
      return acc;
    }, {});
  };

  const vesselTypeCounts = countItems(vessels, "vesselLabel");
  const companyCounts = countItems(vessels, "vesselCompany");

  const vesseltypes = uniqueVesselTypes.map((type) => ({
    id: type,
    label: type.replace("Entity.Vessel.", "").replace(".", " "),
  }));

  const renderVesselType = () => {
    const renderOption = (option: { id: string; label: string }) => (
      <MenuItemOption
        key={option.id}
        value={option.id}
        isChecked={vesselTypeInternal.includes(option.id)}
      >
        {option.label}
        <Tag colorScheme="blue" ml="0.25rem">
          {vesselTypeCounts[option.id]}
        </Tag>
      </MenuItemOption>
    );

    return (
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          mr="0.5rem"
        >
          <HStack>
            <>Vessel Type</>
            {vesselTypeInternal.length >= 1 && (
              <Tag colorScheme="blue">{vesselTypeInternal.length}</Tag>
            )}
          </HStack>
        </MenuButton>
        <MenuList p="0.5rem">
          <Button
            onClick={() => {
              setVesselTypeInternal([]);
            }}
          >
            Clear
          </Button>
          <MenuDivider />
          <MenuOptionGroup
            value={vesselTypeInternal}
            defaultValue={vesselTypeInternal}
            type="checkbox"
            onChange={(ids) => {
              setVesselTypeInternal([...ids]);
            }}
          >
            {vesseltypes.map((option) => renderOption(option))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  };

  const renderCompany = () => {
    const renderOption = (option: string) => (
      <MenuItemOption
        key={option}
        value={option}
        isChecked={companyInternal.includes(option)}
        style={{
          color: option === "SouthSeafood Express Corp" ? "red" : "inherit",
        }}
      >
        {option}
        <Tag colorScheme="blue" ml="0.25rem">
          {companyCounts[option]}
        </Tag>
      </MenuItemOption>
    );

    const validCompanies = uniqueCompanies.filter((company) => company != null);

    const sortedCompanies = validCompanies.sort((a, b) => {
      if (a === "SouthSeafood Express Corp") return -1;
      if (b === "SouthSeafood Express Corp") return 1;
      return a.localeCompare(b);
    });

    return (
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          mr="0.5rem"
        >
          <HStack>
            <>Company</>
            {companyInternal.length >= 1 && (
              <Tag colorScheme="blue">{companyInternal.length}</Tag>
            )}
          </HStack>
        </MenuButton>
        <MenuList p="0.5rem">
          <Button
            onClick={() => {
              setCompanyInternal([]);
            }}
          >
            Clear
          </Button>
          <MenuDivider />
          <MenuOptionGroup
            value={companyInternal}
            defaultValue={companyInternal}
            type="checkbox"
            onChange={(ids) => {
              setCompanyInternal([...ids]);
            }}
          >
            {sortedCompanies.map((company: string) => renderOption(company))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  };

  const getClusterLabel = (vesselId: string) => {
    if (!monthInternal) return "?";
    const cluster = clusterList.find((clstr) =>
      clstr.vessels.includes(vesselId)
    );
    return cluster ? `C${cluster.id}` : "?";
  };

  const getClusterColor = (clusterId: string) => {
    const clusterColor = colorMap2Cluster.find(
      (colorMapping) => colorMapping.cluster === parseInt(clusterId)
    );
    return clusterColor ? clusterColor.color : "gray";
  };

  const renderVesselIds = () => {
    const renderOption = (option: string) => {
      const clusterLabel = getClusterLabel(option);
      const clusterId = clusterLabel.startsWith("C")
        ? clusterLabel.slice(1)
        : "";
      const colorScheme = getClusterColor(clusterId);

      return (
        <MenuItemOption
          key={option}
          value={option}
          isChecked={vesselIdsInternal.includes(option)}
          style={{
            color:
              option === "snappersnatcher7be" || option === "roachrobberdb6"
                ? "red"
                : "inherit",
          }}
        >
          {option}
          <Tag bg={colorScheme} ml="0.25rem">
            {clusterLabel}
          </Tag>
        </MenuItemOption>
      );
    };

    const validVesselIds = uniqueVesselIds.filter(
      (vesselId) => vesselId != null
    );

    const sortedVesselIds = validVesselIds.sort((a, b) => {
      if (a === "snappersnatcher7be" || a === "roachrobberdb6") return -1;
      if (b === "snappersnatcher7be" || b === "roachrobberdb6") return 1;
      return a.localeCompare(b);
    });

    return (
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          mr="0.5rem"
        >
          <HStack>
            <>Vessel ID</>
            {vesselIdsInternal.length >= 1 && (
              <Tag colorScheme="blue">{vesselIdsInternal.length}</Tag>
            )}
          </HStack>
        </MenuButton>
        <MenuList p="0.5rem">
          <Button
            onClick={() => {
              setVesselIdsInternal([]);
            }}
          >
            Clear
          </Button>
          <MenuDivider />
          <MenuOptionGroup
            value={vesselIdsInternal}
            defaultValue={vesselIdsInternal}
            type="checkbox"
            onChange={(ids) => {
              setVesselIdsInternal([...ids]);
            }}
          >
            {sortedVesselIds.map((vesselId: string) => renderOption(vesselId))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  };

  const renderMonth = () => {
    const renderOption = (option: { id: string; label: string }) => {
      return (
        <MenuItemOption
          key={option.id}
          value={option.id}
          isChecked={monthInternal === option.id}
        >
          {option.label}
        </MenuItemOption>
      );
    };

    return (
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          mr="0.5rem"
        >
          <HStack>
            <>Month</>
            {monthInternal && (
              <Tag colorScheme="blue">
                {months.find((month) => month.id === monthInternal)?.label}
              </Tag>
            )}
          </HStack>
        </MenuButton>
        <MenuList p="0.5rem">
          <Button
            onClick={() => {
              setMonthInternal("");
              setClusterInternal([]);
              setLocalClusterList([]);
            }}
          >
            Clear
          </Button>
          <MenuDivider />
          <MenuOptionGroup
            value={monthInternal}
            defaultValue={monthInternal}
            type="radio"
            onChange={(id: string | string[]) => {
              if (typeof id === "string") {
                setMonthInternal(id);
              }
            }}
          >
            {months.map((month: { id: string; label: string }) =>
              renderOption(month)
            )}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    );
  };

  const fetchClusterData = (month: string) => {
    console.log("fetching cluster data for " + month);

    fetch(`${BACKEND_URL}/cluster?type=${month}`)
      .then((response) => response.json())
      .then((data) => {
        let vesselsWithClusterIds = Object.entries(data.clusters).map(
          (entry) => {
            const id = entry[0];
            const clusterId = `${entry[1]}`;
            return { id, clusterId };
          }
        );

        const emptyClusters = [
          ...new Set(
            Object.values(vesselsWithClusterIds).map((entry) => entry.clusterId)
          ),
        ];
        emptyClusters.sort(new Intl.Collator("de", { numeric: true }).compare);

        const getClusterById = (clusterId: string) => {
          const vessels: string[] = [];

          vesselsWithClusterIds.forEach((vessel) => {
            if (vessel.clusterId === clusterId) vessels.push(vessel.id);
          });

          return { id: clusterId, vessels: vessels };
        };

        const clustersById = emptyClusters.map((emptyCluster) => {
          return getClusterById(emptyCluster);
        });

        setLocalClusterList(clustersById);
        setClusterList(clustersById); // Pass the cluster list to the PieGridView component
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => console.log("cluster data fetched"));
  };

  useEffect(() => {
    if (monthInternal) {
      fetchClusterData(monthInternal);
    }
  }, [monthInternal]);

  const filterClusters = useCallback(() => {
    const filteredClusters = clusterList.map((clstr) => {
      const filteredVessels = clstr.vessels.filter((vesselId) => {
        const vessel = vessels.find((v) => v.vesselID === vesselId);
        if (!vessel) return false;
        return (
          (!vesselType.length || vesselType.includes(vessel.vesselLabel)) &&
          (!company.length || company.includes(vessel.vesselCompany)) &&
          (!vesselIds.length || vesselIds.includes(vessel.vesselID))
        );
      });
      return { ...clstr, vessels: filteredVessels };
    });
    setFilteredClusterList(filteredClusters);
  }, [vesselType, company, vesselIds, clusterList, vessels]);

  useEffect(() => {
    filterClusters();
  }, [filterClusters]);

  const renderCluster = () => {
    const renderOption = (option: string) => {
      const colorScheme = getClusterColor(option);
      return (
        <MenuItemOption
          key={option}
          value={option}
          isChecked={clusterInternal.includes(option)}
        >
          {option}
          <Tag bg={colorScheme} ml="0.25rem">
            {
              filteredClusterList.find((cluster) => cluster.id === option)
                ?.vessels.length
            }
          </Tag>
        </MenuItemOption>
      );
    };

    return monthInternal ? (
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          mr="0.5rem"
        >
          <HStack>
            <>Cluster</>
            {clusterInternal.length >= 1 && (
              <Tag colorScheme="blue">{clusterInternal.length}</Tag>
            )}
          </HStack>
        </MenuButton>
        <MenuList p="0.5rem">
          <Button
            onClick={() => {
              setClusterInternal([]);
            }}
          >
            Clear
          </Button>
          <MenuDivider />
          <MenuOptionGroup
            value={clusterInternal}
            defaultValue={clusterInternal}
            type="checkbox"
            onChange={(ids) => {
              setClusterInternal([...ids]);
            }}
          >
            {filteredClusterList.map((cluster: cluster) =>
              renderOption(cluster.id)
            )}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    ) : null;
  };

  useEffect(() => {
    const filters: appliedFilters = {
      vesseltype: vesselType,
      company: company,
      vesselIds: vesselIds,
      cluster: cluster,
      month: month,
    };

    handleSetFilters(filters);
  }, [vesselType, company, vesselIds, cluster, month]);

  return (
    <Accordion allowToggle mb="0.5rem">
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              Filter
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={"0.5rem"}>
          <Flex
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            flexDirection="row"
          >
            <Flex>
              {renderVesselType()}
              {renderCompany()}
              {renderVesselIds()}
              <Box borderRadius="lg" border={"1px solid black"}>
                {renderMonth()}
                {renderCluster()}
              </Box>
            </Flex>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default FilterBox;
