import React, { useState, useEffect } from "react";
import { Box, List, Grid, GridItem, Tag } from "@chakra-ui/react";
import { Reorder } from "framer-motion";
import { Sequence } from "./Sequence";
import { Vessel } from "../../App";
import { colorMap2Cluster, colorToCluster } from "../../support/clusters";

interface SequencesProps {
  vessels: Vessel[];
  viewMode: "tag" | "icon";
  clusterList: cluster[];
}

// Define the cluster interface
interface cluster {
  id: string;
  vessels: string[];
}

export const Sequences = ({
  vessels,
  viewMode,
  clusterList,
}: SequencesProps) => {
  const [itemsList, setItemsList] = useState<Vessel[]>([]);

  useEffect(() => {
    setItemsList(vessels);
  }, [vessels]);

  const vesselColorMap: { [key: string]: string } = {};
  clusterList.forEach((cluster) => {
    const clusterColor = colorMap2Cluster.find(
      (c: colorToCluster) => c.cluster === parseInt(cluster.id)
    )?.color;
    if (clusterColor) {
      cluster.vessels.forEach((vessel: string) => {
        vesselColorMap[vessel] = clusterColor;
      });
    }
  });

  return (
    <Box p="2">
      <Grid templateColumns="220px 1fr" gap={4}>
        <Box>
          <List
            as={Reorder.Group}
            axis="y"
            values={itemsList}
            onReorder={(newOrder: any) => {
              setItemsList(newOrder);
            }}
          >
            {itemsList.map((item) => (
              <GridItem
                as={Reorder.Item}
                key={item.vesselID}
                value={item}
                p={1}
                cursor="grab"
                whileTap={{ cursor: "grabbing" }}
              >
                <Box display="flex" alignItems="center">
                  <Tag size="md" background={vesselColorMap[item.vesselID]}>
                    {item.vesselName}
                  </Tag>
                </Box>
              </GridItem>
            ))}
          </List>
        </Box>
        <Box overflowX="auto" whiteSpace="nowrap">
          {itemsList.map((item) => (
            <Sequence vessel={item} viewMode={viewMode}></Sequence>
          ))}
        </Box>
      </Grid>
    </Box>
  );
};
