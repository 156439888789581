import { Divider } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import "./MapView.css";
import { Map } from "./MapViz";

export const MapView = () => {
  return (
    <Box p="2">
      <Map height={"700px"} width={"100%"} />
      <Divider />
    </Box>
  );
};
