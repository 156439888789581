import React, { useEffect, useState } from "react";
import {} from "@chakra-ui/react";
import { FilterBox, cluster } from "../../elements/FilterBox/FilterBox";
import { BACKEND_URL, Vessel } from "../../App";
import "./ClustersView.css";

interface ClustersViewProps {}

export const ClustersView = ({}: ClustersViewProps) => {
  // show cluster code
  const allClusterNames: string[] = [
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
  ];

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMonth = event.target.value;
    const imgElement = document.getElementById(
      "clusterImage"
    ) as HTMLImageElement;
    if (imgElement) {
      imgElement.src = `clusters/${selectedMonth}.png`;
    }
  };
  return (
    <div>
      <iframe
        src="clusters/sankey.html"
        style={{
          width: "100%",
          height: "50vh", // 30% of the viewport height
          border: "none",
        }}
        title="Flow of Cluster Assignment"
      ></iframe>

      <select onChange={handleSelectChange}>
        {allClusterNames.map((month) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </select>

      <img
        id="clusterImage"
        src="clusters/february.png"
        alt="clusters"
        style={{
          height: "100%",
        }}
      />
    </div>
  );
};
