import React, { useState } from "react";
import { Group } from "@visx/group";
import { Pie } from "@visx/shape";
import { scaleBand, scaleOrdinal } from "@visx/scale";
import { AxisTop, AxisLeft } from "@visx/axis";
import { schemeTableau10 } from "d3-scale-chromatic";
import { ScaleOrdinal } from "d3-scale";
import { colorMap2Cluster, colorToCluster } from "../../support/clusters";

// Define the cluster interface
interface cluster {
  id: string;
  vessels: string[];
}

interface HeatMapProps {
  width: number;
  height: number;
  data: CombinedData;
  threshold: number;
  viewMode: "default" | "highlight";
  fishInfo: { name: string; fishing: string }[];
  onSelection: (selectedData: any) => void;
  clusterList: cluster[];
  opacityMode: "default" | "highlight";
}

interface ScoreData {
  avg_plausibility: number;
  total_qty_tons: number;
}

interface WeekData {
  delivery_reports: object[];
  scores: { [fish: string]: ScoreData };
}

interface VesselData {
  [week: string]: WeekData;
}

interface CombinedData {
  [vessel: string]: VesselData;
}

const vesselScale = scaleBand<string>({
  padding: 0.1,
});

const weekScale = scaleBand<string>({
  padding: 0.1,
});

interface TooltipData {
  vessel: string;
  week: string;
  data: { fish: string; total_qty_tons: number; avg_plausibility: number }[];
  x: number;
  y: number;
}

export const PieGrid: React.FC<HeatMapProps> = ({
  width,
  height,
  data,
  threshold,
  viewMode,
  fishInfo,
  onSelection,
  clusterList,
  opacityMode,
}) => {
  const [highlightedRow, setHighlightedRow] = useState<string | null>(null);
  const [highlightedColumn, setHighlightedColumn] = useState<string | null>(
    null
  );
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
  const [tooltip, setTooltip] = useState<TooltipData | null>(null);

  const margin = { top: 60, left: 125, right: 10, bottom: 10 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const vessels = Object.keys(data);
  // Sort weeks to ensure they are in chronological order
  const weeks = Object.keys(data[vessels[0]]).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  );

  vesselScale.domain(vessels).range([0, innerHeight]);
  weekScale.domain(weeks).range([0, innerWidth]);

  // Calculate min and max sums for normalization
  let minSum = Infinity;
  let maxSum = -Infinity;

  vessels.forEach((vessel) => {
    weeks.forEach((week) => {
      const weekData = data[vessel][week];
      if (weekData && weekData.scores) {
        const scores = weekData.scores;
        const totalQty = Object.values(scores)
          .filter((score) => score.avg_plausibility >= threshold) // Apply threshold filter
          .reduce((sum, { total_qty_tons }) => sum + total_qty_tons, 0);
        if (totalQty < minSum) minSum = totalQty;
        if (totalQty > maxSum) maxSum = totalQty;
      }
    });
  });

  const handleMouseOverRow = (row: string) => {
    setHighlightedRow(row);
  };

  const handleMouseOverColumn = (column: string) => {
    setHighlightedColumn(column);
  };

  const handleMouseOut = () => {
    setHighlightedRow(null);
    setHighlightedColumn(null);
    setTooltip(null);
  };

  const handleMouseOverPie = (
    event: React.MouseEvent<SVGPathElement, MouseEvent>,
    vessel: string,
    week: string,
    data: { fish: string; total_qty_tons: number; avg_plausibility: number }[]
  ) => {
    const svgRect = (event.target as SVGPathElement)
      .closest("svg")!
      .getBoundingClientRect();
    const { clientX, clientY } = event;
    setTooltip({
      vessel,
      week,
      data,
      x: clientX - svgRect.left,
      y: clientY - svgRect.top,
    });
  };

  const handleClickRow = (row: string) => {
    setSelectedRow(row);
    setSelectedColumn(null); // Deselect any column

    const vesselData = data[row];
    const completeVesselData = Object.keys(data[vessels[0]]).reduce(
      (acc, week) => {
        acc[week] = vesselData[week] || { delivery_reports: [], scores: {} };
        fishInfo.forEach((fish) => {
          if (!acc[week].scores[fish.name]) {
            acc[week].scores[fish.name] = {
              avg_plausibility: 0,
              total_qty_tons: 0,
            };
          }
        });
        return acc;
      },
      {} as VesselData
    );

    onSelection({ type: "vessel", selection: row, data: completeVesselData });
  };

  const handleClickColumn = (column: string) => {
    setSelectedColumn(column);
    setSelectedRow(null); // Deselect any row

    const completeWeekData = vessels.reduce((acc, vessel) => {
      const weekData = data[vessel][column] || {
        delivery_reports: [],
        scores: {},
      };
      fishInfo.forEach((fish: { name: string; fishing: string }) => {
        if (!weekData.scores[fish.name]) {
          weekData.scores[fish.name] = {
            avg_plausibility: 0,
            total_qty_tons: 0,
          };
        }
      });
      acc[vessel] = weekData;
      return acc;
    }, {} as { [vessel: string]: WeekData });

    onSelection({ type: "week", selection: column, data: completeWeekData });
  };

  let colorScale: ScaleOrdinal<string, string>;

  if (viewMode === "highlight") {
    colorScale = scaleOrdinal<string, string>({
      domain: fishInfo.map((fish) => fish.name),
      range: fishInfo.map((fish) => {
        switch (fish.fishing) {
          case "allowed":
            return "#59a14f";
          case "forbidden":
            return "#e15759";
          case "ambiguous":
            return "#edc949";
          default:
            return "grey";
        }
      }),
    });
  } else {
    colorScale = scaleOrdinal<string, string>({
      domain: fishInfo.map((fish) => fish.name),
      range: [...schemeTableau10] as string[], // Cast the read-only array to a mutable array type
    });
  }

  const vesselColorMap: { [key: string]: string } = {};
  clusterList.forEach((cluster) => {
    const clusterColor = colorMap2Cluster.find(
      (c: colorToCluster) => c.cluster === parseInt(cluster.id)
    )?.color;
    if (clusterColor) {
      cluster.vessels.forEach((vessel: string) => {
        vesselColorMap[vessel] = clusterColor;
      });
    }
  });

  return (
    <>
      <svg width={width} height={height} id="heatmap-container">
        <Group left={margin.left} top={margin.top}>
          {vessels.map((vessel, vesselIndex) => (
            <Group
              top={vesselScale(vessel)! + vesselScale.bandwidth() / 2}
              key={`vessel-${vessel}`}
              opacity={highlightedRow && highlightedRow !== vessel ? 0.5 : 1}
            >
              {weeks.map((week, weekIndex) => {
                const weekData = data[vessel][week];
                if (!weekData || !weekData.scores) {
                  return null;
                }
                const scores = weekData.scores;
                const filteredScores = Object.entries(scores).filter(
                  ([, value]) => value.avg_plausibility >= threshold
                );
                const pieData = filteredScores.map(([key, value]) => ({
                  key,
                  value: value.total_qty_tons,
                  avg_plausibility: value.avg_plausibility,
                }));

                const sum = pieData.reduce((acc, curr) => acc + curr.value, 0);
                const normalizedPieSize =
                  ((sum - minSum) / (maxSum - minSum)) *
                    Math.min(weekScale.bandwidth(), vesselScale.bandwidth()) +
                  10; // Adding a minimum size

                return (
                  <Group
                    left={weekScale(week)! + weekScale.bandwidth() / 2}
                    key={`week-${week}`}
                    opacity={
                      highlightedColumn && highlightedColumn !== week ? 0.5 : 1
                    }
                  >
                    <Pie
                      data={pieData}
                      pieValue={(d) => d.value as number}
                      outerRadius={normalizedPieSize / 2}
                      innerRadius={0}
                      padAngle={0.01}
                    >
                      {(pie) =>
                        pie.arcs.map((arc) => {
                          const arcPath = pie.path(arc);
                          const arcFill = colorScale(arc.data.key);
                          // Adjust opacity with a minimum value
                          const arcOpacity =
                            opacityMode === "default"
                              ? 1
                              : Math.min(
                                  Math.max(
                                    (arc.data.avg_plausibility + 0.01) * 10,
                                    0.1
                                  ),
                                  1
                                );

                          return (
                            <g
                              key={`arc-${arc.data.key}-${vesselIndex}-${weekIndex}`}
                            >
                              <path
                                d={arcPath!}
                                fill={arcFill}
                                fillOpacity={arcOpacity}
                                onMouseOver={(event) =>
                                  handleMouseOverPie(
                                    event,
                                    vessel,
                                    week,
                                    filteredScores.map(([fish, score]) => ({
                                      fish,
                                      total_qty_tons: score.total_qty_tons,
                                      avg_plausibility: score.avg_plausibility,
                                    }))
                                  )
                                }
                                onMouseOut={handleMouseOut}
                              />
                            </g>
                          );
                        })
                      }
                    </Pie>
                  </Group>
                );
              })}
            </Group>
          ))}
          <AxisTop
            top={0}
            scale={weekScale}
            tickFormat={(d) => d}
            tickLabelProps={(value) => ({
              angle: -45,
              textAnchor: "start",
              fontSize: 10,
              dx: 3,
              cursor: "pointer", // Indicate that the text is clickable
              onClick: () => handleClickColumn(value as string),
              onMouseOver: () => handleMouseOverColumn(value as string),
              onMouseOut: handleMouseOut, // Fix event handler type
              fontWeight: value === selectedColumn ? "bold" : "normal",
              textDecoration: value === selectedColumn ? "underline" : "none",
              textdecorationcolor: value === selectedColumn ? "blue" : "none",
              textdecorationstyle: value === selectedColumn ? "solid" : "none",
            })}
            numTicks={weeks.length}
          />
          <AxisLeft
            scale={vesselScale}
            tickLabelProps={(value) => ({
              angle: -45,
              fontSize: 10,
              textAnchor: "end",
              dy: 3,
              cursor: "pointer", // Indicate that the text is clickable
              onClick: () => handleClickRow(value as string),
              onMouseOver: () => handleMouseOverRow(value as string),
              onMouseOut: handleMouseOut, // Fix event handler type
              fill: vesselColorMap[value] || "black",
              fontWeight: value === selectedRow ? "bold" : "normal",
              textDecoration: value === selectedRow ? "underline" : "none",
              textcecorationcolor: value === selectedRow ? "blue" : "none",
              textcecorationstyle: value === selectedRow ? "solid" : "none",
            })}
            numTicks={vessels.length}
          />
        </Group>
      </svg>
      {tooltip && (
        <div
          style={{
            position: "absolute",
            top: tooltip.y + margin.top,
            left: tooltip.x + margin.left,
            backgroundColor: "white",
            border: "1px solid black",
            padding: "5px",
            pointerEvents: "none",
            zIndex: 99999,
          }}
        >
          <div>
            <strong>Vessel:</strong> {tooltip.vessel}
          </div>
          <div>
            <strong>Week:</strong> {tooltip.week}
          </div>
          {tooltip.data.map((score, index) => (
            <div key={index} style={{ color: colorScale(score.fish) }}>
              <strong>{score.fish}:</strong>
              <div>Avg Tons: {score.total_qty_tons.toFixed(2)}</div>
              <div>Plausibility: {score.avg_plausibility.toFixed(2)}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
